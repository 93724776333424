import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageState } from "../state";

const initialState: MessageState = {
  message: "",
  id: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessageTitle: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setMessageId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    resetMessage: (state) => {
      state.message = "";
      state.id = null!;
    },
  },
});

export const { setMessageTitle, setMessageId, resetMessage } =
  messageSlice.actions;
