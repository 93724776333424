import { OpeningHoursConstant } from "../../../constants/entity";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import {
  useAppDispatch,
  useAppNavigate,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CustomEditIcon,
  DeleteIcon,
  InfoModal,
} from "../../../components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllLanguageAction } from "../../../actions/language";
import { deleteOpeningHoursConstantAction } from "../../../actions/opening-hours-constant";
import { OpeningHoursType } from "../../../constants/action-types";
import {
  setOpenedHoursConstantId,
  setOpenedHoursConstantOpened,
  setOpenedHoursConstantOpenedFrom,
  setOpenedHoursConstantOpenedTo,
  setOpenedHoursConstantType,
} from "../../../constants/reducers/opening-hours-constant.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type OpeningHoursColumnsProps = {
  data: OpeningHoursConstant;
  setState: (prevState: any) => void;
  state: {
    open: boolean;
    infoModalVisible: boolean;
  };
  onModifyClick: () => void;
};

export function OpeningHoursContantTableRow({
  data,
  setState,
  state,
  onModifyClick,
}: OpeningHoursColumnsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteOpeningHoursConstantAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.openingHourConstantQuery]);
    },
    onError: () => {
      setState((prevState: any) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const languageQuery = useQuery({
    queryKey: [QueryKeys.languageQuery],
    queryFn: getAllLanguageAction,
  });

  const handleConfirm = (id: number) => {
    mutate(id);
  };
  const handleCloseInfoModal = () => {
    setState((prevState: any) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleOpenOffData = () => {
    setState((prevState: any) => ({ ...prevState, open: !state.open }));
  };

  const handleClickAdd = (id: number) => {
    return navigate(`create/${id}`);
  };

  const handleOpenModifyModal = (record: OpeningHoursConstant) => {
    dispatch(setOpenedHoursConstantOpened(record.opened));
    dispatch(setOpenedHoursConstantOpenedFrom(record.openedFrom));
    dispatch(setOpenedHoursConstantOpenedTo(record.openedTo));
    dispatch(setOpenedHoursConstantType(record.type));
    dispatch(setOpenedHoursConstantId(record.id));
    onModifyClick();
  };

  return (
    <TableRow key={data?.id}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleOpenOffData}
        >
          {state.open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {data.openedFrom}
      </TableCell>
      <TableCell align="center">{data.openedTo}</TableCell>
      <TableCell align="center">
        {t(`${OpeningHoursType.find((type) => type.id === data.type)?.title}`)}
      </TableCell>
      <TableCell align="center">
        {data.opened ? t("holiday-opened") : t("closed")}
      </TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-opening-hours-contant-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {!languageQuery.isLoading &&
        !(
          languageQuery.data!.languages.length === data.openingHours.length
        ) && (
          <TableCell align="center">
            <AddIcon onClick={() => handleClickAdd(data.id)} />
          </TableCell>
        )}

      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("opening-hours-contant-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
