import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useAppDispatch,
  useAppNavigate,
  useAppParams,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { ErrorModal, HorizontalLinearStepper } from "../../../components";
import { RootState } from "../../../store";
import {
  createOpeningHourAction,
  getOpeningHourAction,
  modifyOpeningHourAction,
} from "../../../actions/opening-hours";
import {
  resetOpenedHours,
  setOpenedHoursId,
  setOpenedHoursName,
} from "../../../constants/reducers/opening-hours.reducer";
import { getOpeningHoursConstantOpeningHourCountAction } from "../../../actions/opening-hours-constant";
import { OpeningHoursMutationForm } from "./components";
import { QueryKeys } from "../../../constants/query-keys";

export function MutateOpeningHourPage() {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const param = useAppParams();
  const navigate = useAppNavigate();
  const { id } = useAppSelector((state: RootState) => state.languages);

  const [state, setState] = useAppState({
    modalVisible: false,
    backwards: false,
  });

  const getContant = useQuery({
    queryKey: [QueryKeys.openingHourQuery],
    queryFn: () =>
      getOpeningHoursConstantOpeningHourCountAction(parseInt(param.id!)),
  });

  const { mutate, error } = useMutation({
    mutationFn: (params: { languageId: number; constantId: number }) =>
      createOpeningHourAction(params),
    onSuccess() {
      queryClient.invalidateQueries([
        QueryKeys.openingHourQuery,
        QueryKeys.openingHourConstantQuery,
      ]);
      dispatch(resetOpenedHours());
      setState((prevState) => ({ ...prevState, backwards: false }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const messageQuery = useMutation({
    mutationFn: (params: { languageId: number; constantId: number }) =>
      getOpeningHourAction(params),
    onSuccess(data) {
      dispatch(setOpenedHoursId(data.id));
      dispatch(setOpenedHoursName(data.name));
      setState((prevState) => ({ ...prevState, backwards: true }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const modifyMessage = useMutation({
    mutationFn: modifyOpeningHourAction,
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.openingHourConstantQuery]);
      dispatch(resetOpenedHours());
      setState((prevState) => ({ ...prevState, backwards: false }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const languageData: any = queryClient.getQueryData([QueryKeys.languageQuery]);
  const handleSave = () => {
    if (state.backwards === true) {
      modifyMessage.mutate();
    } else {
      const params = {
        languageId: id ? id : languageData?.languages[0].id,
        constantId: parseInt(param.id!),
      };
      mutate(params);
    }
  };
  const handleFinish = () => {
    const params = {
      languageId: id,
      constantId: parseInt(param.id!),
    };
    mutate(params);
    navigate("/cms/opening-hours");
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main className="page mutate-message-page">
      <h1>{t("mutate-message-page-title")}</h1>
      <div className="data-creation-container">
        {!getContant.isLoading && (
          <>
            <div>
              <OpeningHoursMutationForm />
            </div>
            <div className="stepper">
              <HorizontalLinearStepper
                onNextClick={handleSave}
                onFinish={handleFinish}
                onPrevClick={(languageId: number) =>
                  messageQuery.mutate({
                    languageId,
                    constantId: parseInt(param.id!),
                  })
                }
                stepSkips={getContant.data}
              />
            </div>
          </>
        )}
      </div>

      {state.modalVisible && (
        <ErrorModal
          error={error ?? messageQuery.error}
          handleClose={handleClose}
        />
      )}
    </main>
  );
}
