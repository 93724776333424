import { Container, Form, Input } from "../../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../../constants/hooks/global-hooks";
import { setTicketName } from "../../../../constants/reducers/ticket.reducer";

import { RootState } from "../../../../store";

const containerStyle = {
  maxWidth: "xl",
};

export function TicketMutationForm() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { title } = useAppSelector((state: RootState) => state.ticket);

  const handleNameChange = (value: string) => {
    dispatch(setTicketName(value));
  };

  return (
    <Container style={containerStyle}>
      <Form direction="column" spacing={2}>
        <Input
          required={true}
          onChange={handleNameChange}
          value={title}
          label={t("ticket-title")}
          helperText={title.length === 0 ? t("filed-is-mandatory-max-255") : ""}
          error={title.length === 0}
          type="text"
        />
      </Form>
    </Container>
  );
}
