import { useQuery } from "@tanstack/react-query";
import {
  useAppDispatch,
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import { getAllLanguageAction } from "../../actions/language";
import {
  LanguageColumns,
  LanguageTableHeaders,
  MutateLanguageModal,
} from "./components";

import "./language.page.scss";
import { resetLanguages } from "../../constants/reducers/language.reducer";
import { QueryKeys } from "../../constants/query-keys";

export function LanguagePage() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
  });

  const { data, error } = useQuery({
    queryKey: [QueryKeys.languageQuery],
    queryFn: getAllLanguageAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible === true) {
      dispatch(resetLanguages());
    }
  };

  return (
    <main className="page">
      <div className="language-page">
        <h1>{t("languages-page-title")}</h1>
        <div className="table-position">
          <CustomButton
            type="contained"
            title={t("create-language-button-title")}
            onClick={handleMutateModal}
          />
          <CustomTable
            data={data?.languages}
            headers={LanguageTableHeaders}
            Columns={LanguageColumns}
            onModifyClick={handleMutateModal}
          />
        </div>
      </div>
      {state.mutateModalVisible && (
        <MutateLanguageModal handleVisible={handleMutateModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
