import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OpeningHoursConstantState } from "../state";

const initialState: OpeningHoursConstantState = {
  openedFrom: "",
  openedTo: "",
  opened: false,
  type: null!,
  id: null!,
};

export const openedHoursConstantSlice = createSlice({
  name: "openedHoursContant",
  initialState,
  reducers: {
    setOpenedHoursConstantId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setOpenedHoursConstantOpenedFrom: (
      state,
      action: PayloadAction<string>
    ) => {
      state.openedFrom = action.payload;
    },
    setOpenedHoursConstantOpenedTo: (state, action: PayloadAction<string>) => {
      state.openedTo = action.payload;
    },
    setOpenedHoursConstantOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    setOpenedHoursConstantType: (state, action: PayloadAction<number>) => {
      state.type = action.payload;
    },

    resetOpenedHoursConstants: (state) => {
      state.openedFrom = "";
      state.openedTo = "";
      state.opened = false;
      state.type = null!;
    },
  },
});

export const {
  setOpenedHoursConstantId,
  setOpenedHoursConstantOpenedFrom,
  setOpenedHoursConstantOpenedTo,
  setOpenedHoursConstantType,
  setOpenedHoursConstantOpened,
  resetOpenedHoursConstants,
} = openedHoursConstantSlice.actions;
