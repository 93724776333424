import { IconButton } from "@mui/material";

type CustomIconButtonProps = {
  children: JSX.Element;
  onClick?: (event: any) => void;
};

export function CustomIconButton({ children, onClick }: CustomIconButtonProps) {
  return <IconButton onClick={onClick}>{children}</IconButton>;
}
