import axios from "axios";
import { store } from "../../store";

export async function modifyProfile() {
  const token = document.cookie.substring(6);
  const { name, password } = store.getState().user;
  const dataToSend = {
    name,
    password,
  };

  return await axios
    .put(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
