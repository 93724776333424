import axios from "axios";

export async function forgotPasswordConfirmAction(token: string) {
  return await axios
    .get(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users/forgot-password-confirm`,
      {
        params: { token },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
