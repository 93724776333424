import UndoIcon from "@mui/icons-material/Undo";
import { CustomIconButton } from "../icon-button";

type UndoIconProps = {
  onClick: () => void;
};

export function CustomUndoIcon({ onClick }: UndoIconProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <UndoIcon />
    </CustomIconButton>
  );
}
