import { useEffect, useRef } from "react";
import { useAppState } from "../../constants/hooks/global-hooks";
import { CustomModal } from "./modal";
import { Container } from "../container";
import { CustomButton } from "../button";
import { ErrorHandler } from "../../constants/errors";

type ErrorModalProps = {
  error: any | null;
  handleClose: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function ErrorModal({ error, handleClose }: ErrorModalProps) {
  const timerRef = useRef<NodeJS.Timeout>();
  const [state, setState] = useAppState({
    timer: 0,
  });

  useEffect(() => {
    if (typeof error?.response.data.data === "number") {
      clearTimeout(timerRef.current);
      timerRef.current = setInterval(() => {
        const remaining = Math.floor(
          (new Date(error?.response.data.data).getTime() -
            new Date().getTime()) /
            1000
        );
        setState((prevState) => ({ ...prevState, timer: remaining }));
      }, 100);
    }
    if (state.timer! === 0) {
      setState((prevState) => ({ ...prevState, timer: null! }));
      clearInterval(timerRef.current);
    } else if (state.timer <= 1 && state.timer !== null) {
      return handleClose;
    } else {
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [error?.response.data.data, state, setState, handleClose]);

  return (
    <CustomModal handleClose={handleClose}>
      <Container style={style}>
        <div className="login-modal-content-container">
          <h3 className="title-text-h3">
            {ErrorHandler({ code: error?.response.data.code })}{" "}
            {error?.response.data.data ? state.timer : ""}
          </h3>
          <div className="login-modal-ok-button">
            <CustomButton title="OK" onClick={handleClose} type="contained" />
          </div>
        </div>
      </Container>
    </CustomModal>
  );
}
