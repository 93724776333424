import { Tooltip } from "@mui/material";

type TooltipProps = {
  title: string;
  children: JSX.Element;
};

export function CustomTooltip({ title, children }: TooltipProps) {
  return <Tooltip title={title}>{children}</Tooltip>;
}
