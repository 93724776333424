import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TicketState } from "../state";

const initialState: TicketState = {
  title: "",
  id: null!,
};

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketName: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },

    setTicketId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    resetTicket: (state) => {
      state.title = "";
      state.id = null!;
    },
  },
});

export const { setTicketName, setTicketId, resetTicket } = ticketSlice.actions;
