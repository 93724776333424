import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserState } from "../state";

const initialState: UserState = {
  name: "",
  password: "",
  email: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setUserPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },

    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    resetUser: (state) => {
      state.name = "";
      state.password = "";
      state.email = "";
    },
  },
});

export const { setUserName, setUserPassword, setUserEmail, resetUser } =
  userSlice.actions;
