import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "../../constants/hooks";
import { getContactAction } from "../../actions/contact";
import { useAppDispatch, useAppState } from "../../constants/hooks/global-hooks";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import {
  ContactColumns,
  ContactTableHeaders,
  MutateContactModal,
} from "./components";
import { resetContact } from "../../constants/reducers/contact.reducer";
import { QueryKeys } from "../../constants/query-keys";

export function ContactPage() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch()
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
  });
  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.contactQuery],
    queryFn: getContactAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible === true) {
      dispatch(resetContact())

    }
  };

  return (
    <main className="page">
      <h1>{t("contact-page-title")}</h1>
      {!isLoading && !data?.id && (
        <CustomButton
          type="outlined"
          title={t("create-contact-button-title")}
          onClick={handleMutateModal}
        />
      )}

      {!isLoading && data && (
        <CustomTable
          data={data?.id ? [data] : []}
          headers={ContactTableHeaders}
          Columns={ContactColumns}
          onModifyClick={handleMutateModal}
        />
      )}

      {state.mutateModalVisible && (
        <MutateContactModal handleVisible={handleMutateModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
