import EditIcon from "@mui/icons-material/Edit";
import { CustomIconButton } from "../icon-button";

type EditIconProps = {
  onClick: () => void;
};

export function CustomEditIcon({ onClick }: EditIconProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <EditIcon />
    </CustomIconButton>
  );
}
