import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../constants/query-keys";
import { forgotPasswordConfirmAction, modifyPasswordAction } from "../../actions/forgot-password";
import { Container, CustomButton, Form, InfoModal, Input, LoadingIcon } from "../../components";
import { useAppTranslation } from "../../constants/hooks";
import { useAppNavigate, useAppState } from "../../constants/hooks/global-hooks";

const style = {
    "& .MuiTextField-root": { m: 1, width: "35ch" },
};


export function ForgotPasswordConfirmPage() {
    const { t } = useAppTranslation()
    const navigate = useAppNavigate()

    const getQueryParam = new URLSearchParams(window.location.search);
    const token = getQueryParam.get("token");
    const [state, setState] = useAppState({
        password: "",
        passwordCheck: "",
        infoModalVisible: false
    })
    const { data, error, isLoading } = useQuery({
        queryKey: [QueryKeys.forgotPasswordConfirmQuery],
        queryFn: () => forgotPasswordConfirmAction(token as string)
    })

    const handlePasswordChange = (value: string) => {
        setState((prevState) => ({ ...prevState, password: value }))
    }

    const handlePasswordCheckChange = (value: string) => {
        setState((prevState) => ({ ...prevState, passwordCheck: value }))
    }

    const { mutate, error: mutateError } = useMutation({
        mutationFn: (data: any) => modifyPasswordAction(data),
        onSuccess: (data) => {
            navigate("/");
        },
        onError: () => {
            setState((prevState) => ({ ...prevState, infoModalVisible: true }));
        },
    });


    const handleCloseInfoModal = () => {
        setState((prevState) => ({ ...prevState, infoModalVisible: false }))
    }

    const handleSubmit = () => {
        mutate({
            password: state.password,
            id: data.id,
            token,
        })
    }

    return (
        <main>
            {isLoading ? (
                <LoadingIcon />
            ) : error ? (
                <h1>
                    {t("forgot-password-error")}
                </h1>
            ) : (
                <Container style={style}>
                    <Form direction="column" spacing={2}>
                        <div className="input-background">
                            <Input label={t("email")} value={data.email} onChange={() => { }} type="text" disabled={true} />
                        </div>
                        <div className="input-background">
                            <Input label={t("password")} value={state.password} onChange={handlePasswordChange} type="password" />
                        </div>
                        <div className="input-background">
                            <Input label={t("password-confirm")} value={state.passwordCheck} onChange={handlePasswordCheckChange} type="password" />
                        </div>
                        <div className="login-button-container">
                            <CustomButton
                                onClick={handleSubmit}
                                title={t("send")}
                                type="outlined"
                            />
                        </div>
                    </Form>

                </Container>


            )}
            {state.infoModalVisible && (
                <InfoModal
                    severity="error"
                    message={t("forgot-password-error")}
                    handleClose={handleCloseInfoModal}
                    error={mutateError}
                />
            )}
        </main>
    )
}