import { MessageConstant } from "../../../constants/entity";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import {
  useAppDispatch,
  useAppNavigate,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CustomEditIcon,
  DeleteIcon,
  InfoModal,
} from "../../../components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllLanguageAction } from "../../../actions/language";
import { deleteMessageConstantAction } from "../../../actions/message-constant";
import {
  setMessageConstantId,
  setMessageConstantValidFrom,
  setMessageConstantValidTo,
} from "../../../constants/reducers/message-constant.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type MessageColumnsProps = {
  data: MessageConstant;
  setState: (prevState: any) => void;
  state: {
    open: boolean;
    infoModalVisible: boolean;
  };
  onModifyClick: () => void;
};

export function MessageContantTableRow({
  data,
  setState,
  state,
  onModifyClick,
}: MessageColumnsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteMessageConstantAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.messageConstantQuery]);
    },
    onError: () => {
      setState((prevState: any) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const languageQuery = useQuery({
    queryKey: [QueryKeys.languageQuery],
    queryFn: getAllLanguageAction,
  });

  const handleConfirm = (id: number) => {
    mutate(id);
  };
  const handleCloseInfoModal = () => {
    setState((prevState: any) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleOpenOffData = () => {
    setState((prevState: any) => ({ ...prevState, open: !state.open }));
  };

  const handleClickAdd = (id: number) => {
    return navigate(`create/${id}`);
  };

  const handleOpenModifyModal = (record: MessageConstant) => {
    dispatch(setMessageConstantValidFrom(record.validFrom));
    dispatch(setMessageConstantValidTo(record.validTo));
    dispatch(setMessageConstantId(record.id));
    onModifyClick();
  };

  return (
    <TableRow key={data?.id}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleOpenOffData}
        >
          {state.open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {new Date(data.validFrom).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        {new Date(data?.validTo).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-message-contant-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {!languageQuery.isLoading &&
        !(languageQuery.data!.languages.length === data.messages.length) && (
          <TableCell align="center">
            <AddIcon onClick={() => handleClickAdd(data.id)} />
          </TableCell>
        )}
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("message-contant-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
