import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "../../constants/hooks";
import {
  useAppDispatch,
  useAppNavigate,
  useAppState,
} from "../../constants/hooks/global-hooks";
import { getAllTicketsAction } from "../../actions/ticket";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import { TicketColumns, TicketTableHeaders } from "./components";
import { resetTicket } from "../../constants/reducers/ticket.reducer";
import { MutateTicketModal } from "./mutate-ticket/components";
import { QueryKeys } from "../../constants/query-keys";
import { resetTicketType } from "../../constants/reducers/ticket-type.reducer";

export function TicketPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch()
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateTicketModalVisible: false,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: [QueryKeys.ticketQuery],
    queryFn: getAllTicketsAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutate = () => {
    navigate("create/ticket");
  };

  const handleMutateTicketModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateTicketModalVisible: !state.mutateTicketModalVisible,
    }));
    if (state.mutateTicketModalVisible === true) {
      dispatch(resetTicket());
      dispatch(resetTicketType());
    }
  }

  return (
    <main className="page">
      <h1>{t("ticket-page-title")}</h1>
      <CustomButton
        type="contained"
        title={t("mutate-ticket-page-title")}
        onClick={handleMutate}

      />
      {!isLoading && (
        <CustomTable
          data={data?.tickets}
          headers={[...[""], ...TicketTableHeaders]}
          Columns={TicketColumns}
          onModifyClick={handleMutateTicketModal}
        />
      )}

      {state.mutateTicketModalVisible && (
        <MutateTicketModal handleVisible={handleMutateTicketModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
