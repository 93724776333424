import CachedSharpIcon from "@mui/icons-material/CachedSharp";
import { CustomIconButton } from "../icon-button";

import "./icon.scss";

export function LoadingIcon() {
  return (
    <CustomIconButton>
      <CachedSharpIcon className="loading-icon" />
    </CustomIconButton>
  );
}
