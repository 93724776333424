import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CustomIconButton } from "../icon-button";

export function ArrowUpIcon() {
  return (
    <CustomIconButton>
      <KeyboardArrowUpIcon />
    </CustomIconButton>
  );
}
