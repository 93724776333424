import { useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "../hooks";
import { QueryKeys } from "../query-keys";
import { RoleEnum } from "../enum";

export function Pages() {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const userData: any = queryClient.getQueryData([QueryKeys.userQuery]);

  const pages = [
    {
      name: t("languages-page-title"),
      path: "/cms/languages",
    },
    {
      name: t("news-button-title"),
      path: "/cms/news",
    },
    {
      name: t("version-page-title"),
      path: "/cms/versions",
    },
    {
      name: t("contact-page-title"),
      path: "/cms/contact",
    },
    {
      name: t("message-page-title"),
      path: "/cms/messages",
    },
    {
      name: t("holiday-page-title"),
      path: "/cms/holiday",
    },
    {
      name: t("opening-hours-page-title"),
      path: "/cms/opening-hours",
    },
    {
      name: t("ticket-page-title"),
      path: "/cms/tickets",
    },
    {
      name: t("admins-button-title"),
      path: "/cms/admins",
    },
  ];

  if (userData?.user.role.name === RoleEnum.ADMIN) {
    return pages.filter((p) => p.path !== "/cms/admins");
  }
  return pages;
}
