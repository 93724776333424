import axios from "axios";
import { store } from "../../store";

export async function modifyNewsAction() {
  const token = document.cookie.substring(6);
  const { id, title, description } = store.getState().news;
  const dataToSend = {
    id,
    title,
    description,
  };

  return await axios
    .put(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/news/modify`,
      dataToSend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
