import { createBrowserRouter } from "react-router-dom";
import { DefaultLayout, LoginLayout } from "../layout";
import {
  LanguagePage,
  LoginPage,
  NewsPage,
  MutateNews,
  VersionPage,
  ContactPage,
  MessagePage,
  MutateMessage,
  HolidayPage,
  OpeningHoursPage,
  MutateOpeningHourPage,
  TicketPage,
  ProfilePage,
  AdminsPage,
  NotFoundPage,
  FirstLoginPage,
  ForgotPasswordPage,
  ForgotPasswordConfirmPage,
} from "../pages";
import { AuthHandler } from "../constants/handler";
import { MutateTicketPage } from "../pages/ticket";

export const router = createBrowserRouter([

  {
    element: (
      <AuthHandler>
        <DefaultLayout />
      </AuthHandler>
    ),
    errorElement: null,
    path: "/cms",
    children: [
      {
        element: <LanguagePage />,
        path: `languages`,
      },
      {
        element: <NewsPage />,
        path: `news`,
      },
      {
        element: <MutateNews />,
        path: `news/create/:id`,
      },
      {
        element: <VersionPage />,
        path: `versions`,
      },
      {
        element: <ContactPage />,
        path: `contact`,
      },
      {
        element: <MessagePage />,
        path: `messages`,
      },
      {
        element: <MutateMessage />,
        path: `messages/create/:id`,
      },
      {
        element: <HolidayPage />,
        path: `holiday`,
      },
      {
        element: <OpeningHoursPage />,
        path: `opening-hours`,
      },
      {
        element: <MutateOpeningHourPage />,
        path: `opening-hours/create/:id`,
      },
      {
        element: <TicketPage />,
        path: `tickets`,
      },
      {
        element: <MutateTicketPage />,
        path: `tickets/create/ticket`,
      },
      {
        element: <ProfilePage />,
        path: `profile`,
      },
      {
        element: <AdminsPage />,
        path: `admins`,
      },
    ],
  },
  {
    element: <LoginLayout />,
    errorElement: null,
    path: "/",
    children: [
      {
        element: <LoginPage />,
        path: `/`,
      },
      {
        element: <ForgotPasswordPage />,
        path: `forgot-password`,
      },
      {
        element: <FirstLoginPage />,
        path: `first-login`,
      },
      {
        element: <ForgotPasswordConfirmPage />,
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    element: <NotFoundPage />,
    path: "*",
  },

]);
