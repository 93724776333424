import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomEditIcon } from "../../../components";
import { useAppDispatch } from "../../../constants/hooks/global-hooks";
import { News } from "../../../constants/entity";
import {
  setNewsDescription,
  setNewsId,
  setNewsTitle,
} from "../../../constants/reducers/news.reducer";

type TableRowsProps = {
  data: News;
  onModifyClick: () => void;
};

export function TableRows({ data, onModifyClick }: TableRowsProps) {
  const dispatch = useAppDispatch();

  const handleOpenModifyModal = (record: News) => {
    dispatch(setNewsId(record.id));
    dispatch(setNewsTitle(record.title));
    dispatch(setNewsDescription(record.description));
    onModifyClick();
  };

  return (
    <TableRow key={data?.id}>
      <TableCell component="th" scope="row" align="center">
        {data?.title.length >= 100
          ? data.title.substring(0, 100) + "..."
          : data.title}
      </TableCell>
      <TableCell align="center">
        {data?.description.length >= 100
          ? data.description.substring(0, 100) + "..."
          : data.description}
      </TableCell>
      <TableCell align="center">{data?.lastModificationBy}</TableCell>
      <TableCell align="center">{data?.language}</TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
    </TableRow>
  );
}
