import {
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import {
  UserIcon,
  Input,
  CustomButton,
  Container,
  Form,
  LoadingIcon,
  ErrorModal,
} from "../../components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { loginAction } from "../../actions/login";
import "./login-page.scss";
import { cookie } from "../../constants/helper";

const style = {
  "& .MuiTextField-root": { m: 1, width: "35ch" },
};

export function LoginPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const queryClient = useQueryClient();

  const [state, setState] = useAppState({
    email: "",
    password: "",
    modalVisible: false,
  });

  const { isLoading, mutate, error } = useMutation({
    mutationFn: loginAction,
    onSuccess: (data) => {
      cookie("token", data.token.accessToken);
      queryClient.setQueryData(["user"], data);
      navigate("/cms/languages");
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handleEmailChange = (e: string) => {
    setState((prevState) => ({ ...prevState, email: e }));
  };

  const handlePasswordChange = (e: string) => {
    setState((prevState) => ({ ...prevState, password: e }));
  };

  const handleLogin = () => {
    mutate({
      email: state.email,
      password: state.password,
    });
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main>
      <Container style={style}>
        <Form direction="column" spacing={1}>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div>
              <div className="input-background">
                <Input
                  value={state.email}
                  onChange={handleEmailChange}
                  icon={<UserIcon />}
                  label={t("email")}
                  iconPosition="end"
                  type="text"
                />
              </div>
              <div className="input-background">
                <Input
                  value={state.password}
                  onChange={handlePasswordChange}
                  label={t("password")}
                  iconPosition="end"
                  type="password"
                />
              </div>


              <div className="login-button-container">
                <CustomButton
                  onClick={handleLogin}
                  title={t("login")}
                  type="outlined"
                />
              </div>
            </div>
          )}
        </Form>
      </Container>
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
