import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "dayjs/locale/hu";

type TimePickerProps = {
  value: any;
  onChange: (value: any | null) => void;
};

export function CustomTimePicker({ value, onChange }: TimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <TimePicker value={value} onChange={onChange} />
    </LocalizationProvider>
  );
}
