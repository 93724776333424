import { useAppTranslation } from "../../constants/hooks";
import "./not-found.page.scss";

export function NotFoundPage() {
    const { t } = useAppTranslation()
    return (
        <div className="error-container">
            <div className="error-title">404</div>
            <div className="error-subtitle">{t("page-not-found")}</div>
            <div>{t("page-not-found-subtitle")}</div>
        </div>
    );
};
