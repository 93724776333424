import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useAppState } from "../../constants/hooks/global-hooks";

type InputProps = {
  onChange: (value: string) => void;
  value: string | number;
  label: string;
  error?: boolean;
  helperText?: string;
  icon?: JSX.Element;
  iconPosition?: "end" | "start";
  type: string;
  required?: boolean;
  onNumberChange?: (value: number) => void;
  disabled?: boolean;
  maxLength?: number;
  multiline?: boolean;
  maxRows?: number;
};

export function Input({
  onChange,
  value,
  label,
  error,
  helperText,
  icon,
  iconPosition,
  type,
  required,
  onNumberChange,
  disabled,
  maxLength,
  multiline,
  maxRows,
}: InputProps) {
  const [state, setState] = useAppState({
    visible: false,
  });

  const handleChangeVisible = () => {
    setState((prevState) => ({ ...prevState, visible: !state.visible }));
  };

  return (
    <TextField
      disabled={disabled}
      required={required}
      type={type === "password" ? (state.visible ? "text" : "password") : type}
      error={error}
      label={label}
      variant="standard"
      InputProps={
        icon && iconPosition === "start"
          ? {
              inputProps: {
                maxLength: maxLength ?? 255,
              },
              startAdornment: (
                <InputAdornment position={iconPosition}>
                  <div onClick={handleChangeVisible}>{icon}</div>
                </InputAdornment>
              ),
            }
          : {
              inputProps: {
                maxLength: maxLength ?? 255,
              },
              endAdornment: <div onClick={handleChangeVisible}>{icon}</div>,
            }
      }
      value={value}
      onChange={(e: any) =>
        type === "number" && onNumberChange
          ? onNumberChange(e.target.value)
          : onChange(e.target.value)
      }
      helperText={helperText}
      multiline={multiline}
      maxRows={maxRows}
    />
  );
}
