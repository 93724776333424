import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { Popup } from "../popup";
import { useAppState } from "../../constants/hooks/global-hooks";
import "./icon.scss";
import { CustomIconButton } from "../icon-button";
type DeleteIconProps = {
  message: string;
  handleConfirm: () => void;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
};

export function DeleteIcon({
  message,
  handleConfirm,
  cancelButtonTitle,
  confirmButtonTitle,
}: DeleteIconProps) {
  const [state, setState] = useAppState({
    popupVisible: false,
    anchorEl: null as HTMLButtonElement | null,
  });

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      popupVisible: false,
      anchorEl: null,
    }));
  };

  const handlePopupVisible = (event: React.MouseEvent<HTMLButtonElement>) => {
    setState((prevState) => ({
      ...prevState,
      popupVisible: true,
      anchorEl: event.currentTarget,
    }));
  };

  return (
    <div>
      <CustomIconButton onClick={handlePopupVisible}>
        <DeleteForeverSharpIcon />
      </CustomIconButton>
      <Popup
        open={state.popupVisible}
        message={message}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        cancelButtonTitle={cancelButtonTitle}
        confirmButtonTitle={confirmButtonTitle}
        anchorEl={state.anchorEl}
      />
    </div>
  );
}
