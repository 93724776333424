import { Modal } from "@mui/material";

type ModalProps = {
  children: JSX.Element;
  handleClose: () => void;
};

export function CustomModal({ children, handleClose }: ModalProps) {
  return (
    <Modal open={true} onClose={handleClose}>
      {children}
    </Modal>
  );
}
