import axios from "axios";

export async function getAllTicketsAction() {
  const token = document.cookie.substring(6);

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/tickets`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
