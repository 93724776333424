import { useQuery } from "@tanstack/react-query";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import { useAppTranslation } from "../../constants/hooks";
import {
  MutateNewsContantModal,
  NewsColumns,
  NewsConstantTableHeaders,
} from "./components";
import { getAllNewsConstantAction } from "../../actions/news-contant";
import {
  useAppDispatch,
  useAppState,
} from "../../constants/hooks/global-hooks";
import "./news.page.scss";
import { resetNews } from "../../constants/reducers/news.reducer";
import { resetNewsContants } from "../../constants/reducers/news-contant.reducer";
import { MutateNewsModal } from "./mutate-news/components";
import { QueryKeys } from "../../constants/query-keys";

export function NewsPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
    mutateNewsModalVisible: false,
  });
  const dispatch = useAppDispatch();

  const { data, error } = useQuery({
    queryKey: [QueryKeys.newsConstantQuery],
    queryFn: getAllNewsConstantAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible === true) {
      dispatch(resetNewsContants());
    }
  };

  const handleMutateNewsModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateNewsModalVisible: !state.mutateNewsModalVisible,
    }));
    if (state.mutateNewsModalVisible === true) {
      dispatch(resetNews());
    }
  };

  return (
    <main className="page">
      <div className="news-page">
        <h1>{t("news-button-title")}</h1>
        <CustomButton
          type="contained"
          title={t("mutate-news-page-title")}
          onClick={handleMutateModal}
        />
        <CustomTable
          data={data?.newsContants}
          headers={[...[""], ...NewsConstantTableHeaders]}
          Columns={NewsColumns}
          onModifyClick={handleMutateModal}
          onModifyOffData={handleMutateNewsModal}
        />

        {state.mutateNewsModalVisible && (
          <MutateNewsModal handleVisible={handleMutateNewsModal} />
        )}

        {state.mutateModalVisible && (
          <MutateNewsContantModal handleVisible={handleMutateModal} />
        )}
        {state.modalVisible && (
          <ErrorModal error={error} handleClose={handleClose} />
        )}
      </div>
    </main>
  );
}
