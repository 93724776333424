import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomModal,
  Form,
  InfoModal,
  Input,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import { createContactAction, modifyContactAction } from "../../../actions/contact";
import {
  resetContact,
  setContactEmail,
  setContactPhoneNumber,
} from "../../../constants/reducers/contact.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type MutateContactModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateContactModal({ handleVisible }: MutateContactModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { email, phoneNumber, id } = useAppSelector(
    (state: RootState) => state.contact
  );

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: id ? modifyContactAction : createContactAction,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.contactQuery]);
      dispatch(resetContact());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    mutate();
  };

  const handleContactEmailChange = (value: string) => {
    dispatch(setContactEmail(value));
  };

  const handleContactPhoneNumberChange = (value: string) => {
    dispatch(setContactPhoneNumber(value));
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">{t("contact-create-modal-title")}</h3>
            <Input
              required={true}
              onChange={handleContactEmailChange}
              value={email}
              label={t("contact-email-label")}
              type="text"
            />
            <Input
              required={true}
              onChange={handleContactPhoneNumberChange}
              value={phoneNumber}
              label={t("contact-phone-number-label")}
              type="text"
            />
            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("contact-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
