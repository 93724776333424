import { Container, Form, Input } from "../../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../../constants/hooks/global-hooks";
import { setOpenedHoursName } from "../../../../constants/reducers/opening-hours.reducer";

import { RootState } from "../../../../store";

const containerStyle = {
  maxWidth: "xl",
};

export function OpeningHoursMutationForm() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state: RootState) => state.openingHours);

  const handleNameChange = (value: string) => {
    dispatch(setOpenedHoursName(value));
  };

  return (
    <Container style={containerStyle}>
      <Form direction="column" spacing={2}>
        <Input
          required={true}
          onChange={handleNameChange}
          value={name}
          label={t("opening-hours-name")}
          helperText={name.length === 0 ? t("filed-is-mandatory-max-255") : ""}
          error={name.length === 0}
          type="text"
        />
      </Form>
    </Container>
  );
}
