import Button from "@mui/material/Button";
import "./button.scss";

type ButtonProps = {
  onClick: () => void;
  title: string;
  type: "text" | "outlined" | "contained" | undefined;
};

export function CustomButton({ onClick, title, type }: ButtonProps) {
  return (
    <Button className="button" variant={type} onClick={onClick}>
      {title}
    </Button>
  );
}
