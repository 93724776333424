import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomEditIcon } from "../../../components";
import { useAppDispatch } from "../../../constants/hooks/global-hooks";
import { Message } from "../../../constants/entity";
import {
  setMessageId,
  setMessageTitle,
} from "../../../constants/reducers/message.reducer";

type TableRowsProps = {
  data: Message;
  onModifyClick: () => void;
};

export function TableRows({ data, onModifyClick }: TableRowsProps) {
  const dispatch = useAppDispatch();

  const handleOpenModifyModal = (record: Message) => {
    dispatch(setMessageId(record.id));
    dispatch(setMessageTitle(record.message));
    onModifyClick();
  };

  return (
    <TableRow key={data?.id}>
      <TableCell align="center" component="th" scope="row">
        {data?.message.length > 100
          ? data.message.substring(0, 100) + "..."
          : data.message}
      </TableCell>
      <TableCell align="center">{data?.language}</TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
    </TableRow>
  );
}
