import RedoIcon from "@mui/icons-material/Redo";
import { CustomIconButton } from "../icon-button";

type RedoIconProps = {
  onClick: () => void;
};

export function CustomRedoIcon({ onClick }: RedoIconProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <RedoIcon />
    </CustomIconButton>
  );
}
