import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useAppDispatch,
  useAppNavigate,
  useAppParams,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { ErrorModal, HorizontalLinearStepper } from "../../../components";
import { RootState } from "../../../store";
import {
  createTicketAction,
  getTicketAction,
  modifyTicketAction,
} from "../../../actions/ticket";
import {
  resetTicket,
  setTicketId,
  setTicketName,
} from "../../../constants/reducers/ticket.reducer";
import { TicketMutationForm } from "./components";
import { QueryKeys } from "../../../constants/query-keys";
import "./mutate-ticket.page.scss";
export function MutateTicketPage() {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const param = useAppParams();
  const navigate = useAppNavigate();
  const { id } = useAppSelector((state: RootState) => state.languages);

  const [state, setState] = useAppState({
    modalVisible: false,
    backwards: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: (params: { languageId: number }) => createTicketAction(params),
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
      dispatch(resetTicket());
      setState((prevState) => ({ ...prevState, backwards: false }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const messageQuery = useMutation({
    mutationFn: (params: { languageId: number; constantId: number }) =>
      getTicketAction(params),
    onSuccess(data) {
      dispatch(setTicketId(data.id));
      dispatch(setTicketName(data.title));
      setState((prevState) => ({ ...prevState, backwards: true }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const modifyMessage = useMutation({
    mutationFn: modifyTicketAction,
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
      dispatch(resetTicket());
      setState((prevState) => ({ ...prevState, backwards: false }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const languageData: any = queryClient.getQueryData([QueryKeys.languageQuery]);
  const handleSave = () => {
    if (state.backwards === true) {
      modifyMessage.mutate();
    } else {
      const params = {
        languageId: id ? id : languageData?.languages[0].id,
        constantId: parseInt(param.id!),
      };
      console.log(languageData);
      mutate(params);
    }
  };

  const handleFinish = () => {
    const params = {
      languageId: id,
      constantId: parseInt(param.id!),
    };
    mutate(params);
    navigate("/cms/tickets");
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main className="page mutate-ticket-page">
      <h1>{t("mutate-ticket-page-title")}</h1>
      <div className="data-creation-container">
        <>
          <div>
            <TicketMutationForm />
          </div>
          <div className="stepper">
            <HorizontalLinearStepper
              onNextClick={handleSave}
              onFinish={handleFinish}
              onPrevClick={(languageId: number) =>
                messageQuery.mutate({
                  languageId,
                  constantId: parseInt(param.id!),
                })
              }
              stepSkips={0}
            />
          </div>
        </>
      </div>

      {state.modalVisible && (
        <ErrorModal
          error={error ?? messageQuery.error}
          handleClose={handleClose}
        />
      )}
    </main>
  );
}
