import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import "dayjs/locale/hu";
import dayjs from "dayjs";

type DateTimePickerProps = {
  value: any;
  onChange: (value: any | null) => void;
};

export function CustomDateTimePicker({ value, onChange }: DateTimePickerProps) {
  console.log(value)
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <DateTimePicker value={value === null ? null : dayjs(value)} onChange={onChange} />
    </LocalizationProvider>
  );
}
