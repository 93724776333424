import axios from "axios";

type ForgotPasswordActionProps = {
  email: string;
};

export async function forgotPasswordAction(params: ForgotPasswordActionProps) {
  const token = document.cookie.substring(6);

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users/forgot-password`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
