import { useAppTranslation } from "../hooks";

type ErrorHandlerProps = {
  code: number;
  data?: number;
};

export function ErrorHandler({ code, data }: ErrorHandlerProps) {
  const { t } = useAppTranslation();
  let errorMessage = "";
  switch (code) {
    case 1:
      errorMessage = t("internal-server-error");
      break;
    case 10:
      errorMessage = t("invalid-body-params-error");
      break;
    case 20 || 30:
      errorMessage = t("no-rights-error");
      break;
    case 1001:
      errorMessage = t("user-not-found-error");
      break;
    case 1002:
      errorMessage = t("invalid-email-or-password-error");
      break;
    case 1003:
      errorMessage = t("user-already-exist-error");
      break;
    case 1004:
      errorMessage = t("too-many-login-attempts-error");
      break;
    case 1005:
      errorMessage = t("cannot-delete-maintainer-error");
      break;
    case 2001:
      errorMessage = t("version-not-found-error");
      break;
    case 3001:
      errorMessage = t("language-already-exist-error");
      break;
    case 3002:
      errorMessage = t("language-not-found-error");
      break;
    case 3003:
      errorMessage = t("cannot-delete-language-error");
      break;
    case 4001:
      errorMessage = t("password-reset-token-invalid-error");
      break;
    case 5001:
      errorMessage = t("session-expired-error");
      break;
    case 6001:
      errorMessage = t("file-not-found-error");
      break;
    case 7001:
      errorMessage = t("must-create-news-for-all-languages-error");
      break;
    case 7002:
      errorMessage = t("news-not-exist-error");
      break;
    case 7003:
      errorMessage = t("news-already-exist-error");
      break;
    case 8001:
      errorMessage = t("message-not-found-error");
      break;
    case 8002:
      errorMessage = t("must-create-message-for-all-languages-error");
      break;
    case 9001:
      errorMessage = t("must-create-opening-hours-for-all-languages-error");
      break;
    case 9002:
      errorMessage = t("opening-hour-not-found-error");
      break;
    case 10001:
      errorMessage = t("holiday-opening-not-found-error");
      break;
    case 11001:
      errorMessage = t("ticket-not-found-error");
      break;
    case 11002:
      errorMessage = t("ticket-type-not-found-error");
      break;
    case 12001:
      errorMessage = t("cannot-create-another-contact-error");
      break;
    case 12002:
      errorMessage = t("contact-not-found-error");
      break;
    case 13001:
      errorMessage = t("news-constant-not-found-error");
      break;
    case 14001:
      errorMessage = t("message-constant-not-found-error");
      break;
    case 15001:
      errorMessage = t("opening-hours-constant-not-found-error");
      break;
    default:
      break;
  }
  return errorMessage;
}
