import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Holiday } from "../../../constants/entity";
import { CustomEditIcon, DeleteIcon, InfoModal } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useAppDispatch,
  useAppState,
} from "../../../constants/hooks/global-hooks";
import { deleteHolidayAction } from "../../../actions/holiday";
import {
  setHolidayFrom,
  setHolidayId,
  setHolidayOpened,
  setHolidayTo,
} from "../../../constants/reducers/holiday.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type HolidayColumnsProps = {
  data: Holiday;
  onModifyClick: () => void;
};

export function HolidayColumns({ data, onModifyClick }: HolidayColumnsProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    infoModalVisible: false,
  });
  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteHolidayAction(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.holidayQuery]);
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleConfirm = (id: number) => {
    mutate(id);
  };

  const handleOpenModifyModal = (record: Holiday) => {
    dispatch(setHolidayId(record.id));
    dispatch(setHolidayFrom(new Date(record.from)));
    dispatch(setHolidayTo(record?.to as any as Date));
    dispatch(setHolidayOpened(record.opened));
    onModifyClick();
  };

  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" align="center">
        {new Date(data.from).toLocaleString()}
      </TableCell>
      <TableCell align="center">
        {data.to ? new Date(data.to).toLocaleString() : ""}
      </TableCell>
      <TableCell align="center">
        {data.opened ? t("holiday-opened") : t("closed")}
      </TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-holiday-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("holiday-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
