import { useMutation } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  ErrorModal,
  Form,
  Input,
  LoadingIcon,
} from "../../components";
import {
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../constants/hooks/global-hooks";
import { firstLoginAction } from "../../actions/user";
import "./first-login.page.scss";
import { useEffect } from "react";

const style = {
  "& .MuiTextField-root": { m: 1, width: "35ch" },
};

export function FirstLoginPage() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const [state, setState] = useAppState({
    password: "",
    passwordCheck: "",
    modalVisible: false,
    success: false,
  });

  const { isLoading, mutate, error } = useMutation({
    mutationFn: (data: any) => firstLoginAction(data),
    onSuccess: (data) => {
      setState((prevState) => ({ ...prevState, success: true }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handlePasswordChange = (value: string) => {
    setState((prevState) => ({ ...prevState, password: value }));
  };

  const handlePasswordCheckChange = (value: string) => {
    setState((prevState) => ({ ...prevState, passwordCheck: value }));
  };

  const handleSubmit = () => {
    mutate({
      password: state.password,
      passwordCheck: state.passwordCheck,
    });
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  useEffect(() => {
    if (state.success) {
      return navigate("/cms/languages");
    }
  }, [state.success, navigate]);

  return (
    <main>
      <Container style={style}>
        <Form direction="column" spacing={2}>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div>
              <div className="input-background">
                <Input
                  label={t("password")}
                  type="password"
                  value={state.password}
                  onChange={handlePasswordChange}
                  required={true}
                />
              </div>
              <div className="input-background">
                <Input
                  label={t("password-confirm")}
                  type="password"
                  value={state.passwordCheck}
                  onChange={handlePasswordCheckChange}
                  required={true}
                />
              </div>
              <div className="login-button-container">
                <CustomButton
                  onClick={handleSubmit}
                  title={t("continue")}
                  type="outlined"
                />
              </div>
            </div>
          )}
        </Form>
      </Container>
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
