import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "../../../../constants/hooks";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
} from "../../../../constants/hooks/global-hooks";
import { RootState } from "../../../../store";
import {
  Container,
  CustomButton,
  CustomModal,
  Form,
  InfoModal,
  Input,
} from "../../../../components";

import { modifyTicketAction } from "../../../../actions/ticket";
import {
  resetTicket,
  setTicketName,
} from "../../../../constants/reducers/ticket.reducer";
import { QueryKeys } from "../../../../constants/query-keys";

type MutateTicketModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateTicketModal({ handleVisible }: MutateTicketModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { title } = useAppSelector((state: RootState) => state.ticket);

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: modifyTicketAction,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
      dispatch(resetTicket());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    mutate();
  };

  const handleTitleChange = (value: string) => {
    dispatch(setTicketName(value));
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">{t("modify")}</h3>
            <Input
              required={true}
              onChange={handleTitleChange}
              value={title}
              label={t("ticket-title")}
              helperText={
                title.length === 0 ? t("filed-is-mandatory-max-255") : ""
              }
              error={title.length === 0}
              type="text"
            />

            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
