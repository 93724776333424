import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NewsContantState } from "../state";

const initialState: NewsContantState = {
  id: null!,
  picture: null,
  imageUrl: "",
  date: null,
};

export const newsContantSlice = createSlice({
  name: "newsContant",
  initialState,
  reducers: {
    setNewsContantId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    setNewsContantPicture: (state, action: PayloadAction<File | null>) => {
      state.picture = action.payload;
    },
    setNewsContantDate: (state, action: PayloadAction<Date | null>) => {
      state.date = action.payload;
    },
    setNewsContantImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },

    resetNewsContants: (state) => {
      state.picture = null;
      state.date = null;
      state.imageUrl = "";
    },
  },
});

export const {
  setNewsContantId,
  setNewsContantPicture,
  setNewsContantDate,
  setNewsContantImageUrl,
  resetNewsContants,
} = newsContantSlice.actions;
