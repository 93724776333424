import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "../../constants/hooks";
import { useAppDispatch, useAppState } from "../../constants/hooks/global-hooks";
import { getAllHolidayAction } from "../../actions/holiday";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import {
  HolidayColumns,
  HolidayTableHeaders,
  MutateHolidayModal,
} from "./components";

import "./holiday.page.scss";
import { resetHoliday } from "../../constants/reducers/holiday.reducer";
import { QueryKeys } from "../../constants/query-keys";

export function HolidayPage() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch()
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
  });
  const { data, error } = useQuery({
    queryKey: [QueryKeys.holidayQuery],
    queryFn: getAllHolidayAction,
  });
  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible) {
      dispatch(resetHoliday())
    }
  };
  return (
    <main className="page">
      <div className="holiday-page">
        <h1>{t("holiday-page-title")}</h1>
        <CustomButton
          type="contained"
          title={t("create-holiday-button-title")}
          onClick={handleMutateModal}
        />
        <CustomTable
          data={data?.holidayData}
          headers={HolidayTableHeaders}
          Columns={HolidayColumns}
          onModifyClick={handleMutateModal}
        />
        {state.mutateModalVisible && (
          <MutateHolidayModal handleVisible={handleMutateModal} />
        )}
        {state.modalVisible && (
          <ErrorModal error={error} handleClose={handleClose} />
        )}
      </div>
    </main>
  );
}
