import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { CustomIconButton } from "../icon-button";

type ZoomInIconProps = {
  onClick: () => void;
};

export function CustomZoomInIcon({ onClick }: ZoomInIconProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <ZoomInIcon />
    </CustomIconButton>
  );
}
