import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageConstantState } from "../state";

const initialState: MessageConstantState = {
  validTo: null,
  validFrom: null,
  id: null!,
};

export const messageConstantSlice = createSlice({
  name: "messageContant",
  initialState,
  reducers: {
    setMessageConstantId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    setMessageConstantValidFrom: (
      state,
      action: PayloadAction<Date | null>
    ) => {
      state.validFrom = action.payload;
    },

    setMessageConstantValidTo: (state, action: PayloadAction<Date | null>) => {
      state.validTo = action.payload;
    },

    resetMessageConstants: (state) => {
      state.validFrom = null;
      state.validTo = null;
    },
  },
});

export const {
  setMessageConstantId,
  setMessageConstantValidFrom,
  setMessageConstantValidTo,
  resetMessageConstants,
} = messageConstantSlice.actions;
