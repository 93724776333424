import axios from "axios";

type LoginProps = {
  email: string;
  password: string;
};

export async function loginAction({ email, password }: LoginProps) {
  const params = {
    email,
    password,
  };

  return await axios
    .post(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/users/login`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
