import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NewsState } from "../state";

const initialState: NewsState = {
  title: "",
  description: "",
  id: null!,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setNewsDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setNewsId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    resetNews: (state) => {
      state.title = "";
      state.description = "";
      state.id = null!;
    },
  },
});

export const { setNewsTitle, setNewsDescription, resetNews, setNewsId } =
  newsSlice.actions;
