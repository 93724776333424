import { Link } from "@mui/material";

import "./link.scss";
type LinkProps = {
  to: string;
  title: string;
};

export function Navlink({ to, title }: LinkProps) {
  return (
    <Link href={to} underline="none" className="link">
      {title}
    </Link>
  );
}
