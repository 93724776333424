import { useAppTranslation } from "../hooks";

export function Settings() {
  const { t } = useAppTranslation();

  const settings = [
    {
      name: t("profile-button-title"),
      path: "profile",
    },
    {
      name: t("logout"),
      path: "/",
    },
  ];

  return settings;
}
