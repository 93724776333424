import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Version } from "../../../constants/entity";
import { DeleteIcon, InfoModal } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppState } from "../../../constants/hooks/global-hooks";
import { deleteVersionAction } from "../../../actions/version";
import { QueryKeys } from "../../../constants/query-keys";

type VersionColumnsProps = {
  data: Version;
};

export function VersionColumns({ data }: VersionColumnsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    infoModalVisible: false,
  });
  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteVersionAction(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.versionQuery]);
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleConfirm = (id: number) => {
    mutate(id);
  };

  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" align="center">
        {data.version}
      </TableCell>
      <TableCell align="center">
        {new Date(data.created).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-version-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("version-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
