import React, { useState, useRef, useMemo, useCallback } from "react";
import { CustomModal, InfoModal } from "../modal";

import "./picture-uploader.scss";
import { PictureCropper } from "./picture-cropper";
import { useAppTranslation } from "../../constants/hooks";
import { Container } from "../container";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

type PictureUploaderProps = {
  picture: File;
  pictureUrl: string;
  setPictureUrl: (value: string) => void;
  setPicture: (value: File) => void;
  disabled?: boolean;
};

export const PictureUploader = ({
  picture,
  pictureUrl,
  setPictureUrl,
  setPicture,
  disabled,
}: PictureUploaderProps) => {
  const { t } = useAppTranslation();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const removePictureFiles = useCallback(() => {
    setPicture(null!);
    setPictureUrl("");
  }, [setPicture, setPictureUrl]);

  const handleFile = useCallback(
    (file: File | undefined) => {
      if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
        if (file.size <= 5 * 1024 * 1024) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          setPicture(file);
          setPictureUrl(file.name);
          setModalVisible(true);
        } else {
          <InfoModal
            severity="warning"
            message={t("picture-file-size-too-big")}
            handleClose={() => null}
            error={false}
          />;
          removePictureFiles();
        }
      } else {
        removePictureFiles();
      }
    },
    [removePictureFiles, setPicture, setPictureUrl, t]
  );

  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setPictureUrl(reader.result?.toString() || "")
        );
        reader.readAsDataURL(e.target.files[0]);
        handleFile(e.target.files[0]);
        e.target.value = "";
      }
    },
    [handleFile, setPictureUrl]
  );

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (!disabled) {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        const file = event.dataTransfer.files?.[0];
        handleFile(file);
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          setPictureUrl(reader.result?.toString() || "")
        );
        reader.readAsDataURL(file);
      }
    },
    [handleFile, setPictureUrl, disabled]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!disabled) {
        setIsDragOver(true);
      }
    },
    [disabled]
  );

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return useMemo(() => {
    return (
      <div className={`picture-uploader-container `}>
        <div
          className="background-container"
          onDrop={handleFileDrop}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onClick={handleContainerClick}
        >
          <input
            ref={fileInputRef}
            type="file"
            onChange={onSelectFile}
            accept=".png,.jpg,.jpeg"
            disabled={disabled}
          />
          <div
            className={`picture-preview-container ${isDragOver ? "drag-over" : ""
              }`}
          >
            {picture && previewCanvasRef.current !== null ? (
              <canvas
                className="picture-preview"
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                }}
              />
            ) : (
              pictureUrl && (
                <img
                  className="picture-preview"
                  src={pictureUrl}
                  alt={pictureUrl}
                />
              )
            )}
          </div>
        </div>
        {modalVisible && !disabled && (
          <CustomModal handleClose={() => setModalVisible(false)}>
            <Container style={containerStyle}>
              <PictureCropper
                pictureUrl={pictureUrl}
                setPicture={setPicture}
                previewCanvasRef={previewCanvasRef}
                handleClose={() => setModalVisible(false)}
              />
            </Container>
          </CustomModal>
        )}
      </div>
    );
  }, [
    handleFileDrop,
    isDragOver,
    modalVisible,
    onSelectFile,
    picture,
    pictureUrl,
    previewCanvasRef,
    setPicture,
    disabled,
    handleDragOver,
  ]);
};
