import { Outlet } from "react-router-dom";

import "./login-layout.scss";

export function LoginLayout() {
  return (
    <main className="login-layout">
      <div className="background-overlay"></div>
      <Outlet />
    </main>
  );
}
