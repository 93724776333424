import { Container, Form, Input } from "../../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../../constants/hooks/global-hooks";
import {
  setNewsDescription,
  setNewsTitle,
} from "../../../../constants/reducers/news.reducer";
import { RootState } from "../../../../store";

const containerStyle = {
  maxWidth: "xl",
};

export function NewsMutationForm() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { title, description } = useAppSelector(
    (state: RootState) => state.news
  );

  const handleTitleChange = (value: string) => {
    dispatch(setNewsTitle(value));
  };

  const handleChangeDescription = (value: string) => {
    dispatch(setNewsDescription(value));
  };

  return (
    <Container style={containerStyle}>
      <Form direction="column" spacing={2}>
        <Input
          required={true}
          onChange={handleTitleChange}
          value={title}
          label={t("news-title")}
          helperText={title.length === 0 ? t("filed-is-mandatory-max-255") : ""}
          error={title.length === 0}
          type="text"
        />
        <Input
          required={true}
          onChange={handleChangeDescription}
          value={description}
          label={t("news-description")}
          helperText={
            description.length === 0 ? t("filed-is-mandatory-max-800") : ""
          }
          error={description.length === 0}
          multiline={true}
          type="text"
          maxLength={800}
          maxRows={5}
        />
      </Form>
    </Container>
  );
}
