import Switch from '@mui/material/Switch';

type CustomSwitchProps = {
    onChange: (value: string) => void
    title: string
    value: boolean
}

export function CustomSwitch({ value, onChange, title }: CustomSwitchProps) {
    return <Switch value={value} onChange={(e) => onChange(e.target.value)} title={title} />
}