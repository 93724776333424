import axios from "axios";

export async function deleteNewsConstantAction(id: number) {
  const token = document.cookie.substring(6);

  return await axios
    .delete(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/news-contant/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
