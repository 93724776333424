import { useMutation } from "@tanstack/react-query";
import { Container, CustomButton, Form, InfoModal, Input } from "../../components";
import { useAppTranslation } from "../../constants/hooks";
import { useAppState } from "../../constants/hooks/global-hooks";
import { forgotPasswordAction, } from "../../actions/forgot-password";


const style = {
    "& .MuiTextField-root": { m: 1, width: "35ch" },
};

export function ForgotPasswordPage() {
    const { t } = useAppTranslation()
    const [state, setState] = useAppState({
        email: "",
        infoModalVisible: false
    })
    const handleEmailChange = (value: string) => {
        setState((prevState) => ({ ...prevState, email: value }))
    }

    const { mutate } = useMutation({
        mutationFn: (data: any) => forgotPasswordAction(data),
        onSuccess() {
            setState((prevState) => ({ ...prevState, infoModalVisible: true }))

        }
    })

    const handleCloseInfoModal = () => {
        setState((prevState) => ({ ...prevState, infoModalVisible: false }))
    }

    const handleSubmit = () => {
        mutate({
            email: state.email
        })
    }



    return (
        <main>
            <Container style={style}>
                <Form direction="column" spacing={2}>
                    <div className="input-background">
                        <Input label={t("email")} value={state.email} onChange={handleEmailChange} type="text" />

                    </div>
                    <div className="login-button-container">
                        <CustomButton
                            onClick={handleSubmit}
                            title={t("send")}
                            type="outlined"
                        />
                    </div>
                </Form>
            </Container>

            {state.infoModalVisible && (
                <InfoModal
                    severity="success"
                    message={t("forgot-password-message")}
                    handleClose={handleCloseInfoModal}
                    error={null}
                />
            )}
        </main>
    )
}