import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CustomEditIcon,
  DeleteIcon,
  InfoModal,
} from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppDispatch } from "../../../constants/hooks/global-hooks";
import { Ticket } from "../../../constants/entity";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteTicketAction } from "../../../actions/ticket";
import { getAllLanguageAction } from "../../../actions/language";
import { IconButton } from "@mui/material";
import {
  resetTicket,
  setTicketId,
  setTicketName,
} from "../../../constants/reducers/ticket.reducer";
import { TicketTypeMutationForm } from "../mutate-ticket-type";
import { QueryKeys } from "../../../constants/query-keys";

type TableRowsProps = {
  data: Ticket;
  setState: (prevState: any) => void;
  state: {
    open: boolean;
    infoModalVisible: boolean;
    mutateModalVisible: boolean;
  };
  onModifyClick: () => void;
};

export function TableRows({
  data,
  setState,
  state,
  onModifyClick,
}: TableRowsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteTicketAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
    },
    onError: () => {
      setState((prevState: any) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const languageQuery = useQuery({
    queryKey: [QueryKeys.languageQuery],
    queryFn: getAllLanguageAction,
  });
  const handleCloseInfoModal = () => {
    setState((prevState: any) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleConfirm = (id: number) => {
    mutate(id);
  };

  const handleClickAdd = (id: number) => {
    dispatch(setTicketId(id));
    setState((prevState: any) => ({ ...prevState, mutateModalVisible: true }));
  };

  const handleOpenOffData = () => {
    setState((prevState: any) => ({ ...prevState, open: !state.open }));
  };

  const handleVisible = () => {
    setState((prevState: any) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
    if (state.mutateModalVisible) {
      dispatch(resetTicket());
    }
  };

  const handleOpenModifyModal = (record: Ticket) => {
    dispatch(setTicketId(record.id));
    dispatch(setTicketName(record.title));
    onModifyClick();
  };

  return (
    <>
      <TableRow key={data?.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenOffData}
          >
            {state.open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {data?.title.length > 100
            ? data.title.substring(0, 100) + "..."
            : data.title}
        </TableCell>
        <TableCell align="center">{data?.language}</TableCell>
        <TableCell align="center">
          <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
        </TableCell>
        <TableCell align="center">
          <DeleteIcon
            message={t("delete-ticket-popup-message")}
            cancelButtonTitle={t("no")}
            confirmButtonTitle={t("yes")}
            handleConfirm={() => handleConfirm(data.id)}
          />
        </TableCell>
        {!languageQuery.isLoading &&
          !(languageQuery.data!.languages.length === data) && (
            <TableCell align="center">
              <AddIcon onClick={() => handleClickAdd(data.id)} />
            </TableCell>
          )}

        {state.infoModalVisible && (
          <InfoModal
            severity="error"
            message={t("opening-hours-contant-save-error-message")}
            handleClose={handleCloseInfoModal}
            error={error}
          />
        )}
      </TableRow>
      {state.mutateModalVisible && (
        <TicketTypeMutationForm handleVisible={handleVisible} />
      )}
    </>
  );
}
