import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "../../../../constants/hooks";
import {
    useAppDispatch,
    useAppSelector,
    useAppState,
} from "../../../../constants/hooks/global-hooks";
import { RootState } from "../../../../store";
import {
    Container,
    CustomButton,
    CustomModal,
    Form,
    InfoModal,
    Input,
} from "../../../../components";

import { modifyMessageAction } from "../../../../actions/message";
import { resetMessage, setMessageTitle } from "../../../../constants/reducers/message.reducer";
import { QueryKeys } from "../../../../constants/query-keys";

type MutateMessageModalProps = {
    handleVisible: () => void;
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid transparent",
    boxShadow: 12,
    borderRadius: 12,
    p: 4,
};

export function MutateMessageModal({ handleVisible }: MutateMessageModalProps) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const { id, message } = useAppSelector(
        (state: RootState) => state.message
    );

    const [state, setState] = useAppState({
        infoModalVisible: false,
    });

    const { mutate, error } = useMutation({
        mutationFn: modifyMessageAction,
        onSuccess: (data) => {
            queryClient.invalidateQueries([QueryKeys.messageConstantQuery]);
            dispatch(resetMessage());
            return handleVisible();
        },
        onError: () => {
            setState((prevState) => ({ ...prevState, infoModalVisible: true }));
        },
    });

    const handleSave = () => {
        mutate();
    };

    const handleMessageChange = (value: string) => {
        dispatch(setMessageTitle(value));
    };

    const handleCloseInfoModal = () => {
        setState((prevState) => ({ ...prevState, infoModalVisible: false }));
    };

    return (
        <main>
            <CustomModal handleClose={handleVisible}>
                <Container style={style}>
                    <Form direction="column" spacing={2}>
                        <h3 className="title-text-h3">
                            {id ? t("modify") : t("language-create-modal-title")}
                        </h3>
                        <Input
                            required={true}
                            onChange={handleMessageChange}
                            value={message}
                            label={t("message-title")}
                            helperText={
                                message.length === 0 ? t("filed-is-mandatory-max-255") : ""
                            }
                            error={message.length === 0}
                            type="text"
                        />

                        <CustomButton
                            onClick={handleSave}
                            title={t("save")}
                            type="outlined"
                        />
                    </Form>
                </Container>
            </CustomModal>
            {state.infoModalVisible && (
                <InfoModal
                    severity="error"
                    message={t("language-save-error-message")}
                    handleClose={handleCloseInfoModal}
                    error={error}
                />
            )}
        </main>
    );
}
