import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Container } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppState } from "../../../constants/hooks/global-hooks";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { Ticket } from "../../../constants/entity";
import { TableRows } from "./ticket-table-row";
import { TicketTypeTableHeaders } from "./ticket-types-table-header";
import { TicketTypeTableRows } from "./ticket-type-table-rows";

type TicketColumnsProps = {
  data: Ticket;
  onModifyClick: () => void;
};

const containerStyle = {
  margin: 1,
};

export function TicketColumns({ data, onModifyClick }: TicketColumnsProps) {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    infoModalVisible: false,
    open: false,
    mutateModalVisible: false,
  });

  return (
    <React.Fragment>
      <TableRows onModifyClick={onModifyClick} data={data} state={state} setState={setState} />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={state.open} timeout="auto" unmountOnExit>
            <Container style={containerStyle}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {TicketTypeTableHeaders.map((header) => {
                      return <TableCell align="center">{t(header)}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.ticketTypes?.map((ticket) => (
                    <TicketTypeTableRows data={ticket} state={state} setState={setState} />
                  ))}
                </TableBody>
              </Table>
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
