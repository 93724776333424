import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomModal,
  CustomSwitch,
  CustomTimePicker,
  Form,
  InfoModal,
  Select,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import { createOpeningHoursConstantAction, modifyOpeningHourConstantAction } from "../../../actions/opening-hours-constant";
import {
  resetOpenedHoursConstants,
  setOpenedHoursConstantOpened,
  setOpenedHoursConstantOpenedFrom,
  setOpenedHoursConstantOpenedTo,
  setOpenedHoursConstantType,
} from "../../../constants/reducers/opening-hours-constant.reducer";
import { OpeningHoursType } from "../../../constants/action-types";
import { QueryKeys } from "../../../constants/query-keys";

type MutateOpeningHoursConstantModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateOpeningHoursConstantModal({
  handleVisible,
}: MutateOpeningHoursConstantModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { id, opened, openedFrom, openedTo, type } = useAppSelector(
    (state: RootState) => state.openingHoursConstant
  );

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: id ? modifyOpeningHourConstantAction : createOpeningHoursConstantAction,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.openingHourConstantQuery]);
      dispatch(resetOpenedHoursConstants());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    mutate();
  };

  const handleOpenedFromChange = (value: string) => {
    dispatch(setOpenedHoursConstantOpenedFrom(value));
  };

  const handleOpenedToChange = (value: string) => {
    dispatch(setOpenedHoursConstantOpenedTo(value));
  };

  const handleOpenedChange = () => {
    dispatch(setOpenedHoursConstantOpened(!opened));
  };

  const handleTypeChange = (value: string) => {
    dispatch(setOpenedHoursConstantType(parseInt(value)));
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">
              {t("message-constant-create-modal-title")}
            </h3>
            <CustomTimePicker
              value={openedFrom}
              onChange={handleOpenedFromChange}
            />
            <CustomTimePicker
              value={openedTo}
              onChange={handleOpenedToChange}
            />
            <CustomSwitch
              value={opened}
              onChange={handleOpenedChange}
              title="asd"
            />
            <Select
              value={type}
              onChange={handleTypeChange}
              title={t("type")}
              options={OpeningHoursType}
            />
            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("opening-hours-constant-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
