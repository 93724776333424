import { Container, Form, Input } from "../../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppTranslation,
} from "../../../../constants/hooks/global-hooks";
import { setMessageTitle } from "../../../../constants/reducers/message.reducer";

import { RootState } from "../../../../store";

const containerStyle = {
  maxWidth: "xl",
};

export function MessageMutationForm() {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { message } = useAppSelector((state: RootState) => state.message);

  const handleMessageChange = (value: string) => {
    dispatch(setMessageTitle(value));
  };

  return (
    <Container style={containerStyle}>
      <Form direction="column" spacing={2}>
        <Input
          required={true}
          onChange={handleMessageChange}
          value={message}
          label={t("message-title")}
          helperText={
            message.length === 0 ? t("filed-is-mandatory-max-255") : ""
          }
          error={message.length === 0}
          type="text"
        />
      </Form>
    </Container>
  );
}
