import { Navigate, Outlet } from "react-router-dom";
import { Header } from "./header";
import "./default.layout.scss";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../constants/query-keys";
import { getMeAction } from "../../actions/user";

export function DefaultLayout() {
  const { data: userData, error } = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    staleTime: 1000 * 60 * 5,
  });

  if (error) {
    console.error(error);
  }

  if (userData && userData.user?.firstLogin) {
    return <Navigate to={"/first-login"} />;
  }


  return (
    <main className="default-layout">
      <div className="background-overlay"></div>
      <Header />
      <Outlet />
    </main>
  );
}
