import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CustomIconButton } from "../icon-button";

type AddIonProps = {
  onClick: () => void;
};

export function AddIcon({ onClick }: AddIonProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <AddCircleIcon />
    </CustomIconButton>
  );
}
