import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LanguageState } from "../state";

const initialState: LanguageState = {
  picture: null,
  imageUrl: "",
  languageCode: "",
  id: null!,
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguagePicture: (state, action: PayloadAction<File | null>) => {
      state.picture = action.payload;
    },
    setLanguageCode: (state, action: PayloadAction<string>) => {
      state.languageCode = action.payload;
    },
    setLanguageImageUrl: (state, action: PayloadAction<string>) => {
      state.imageUrl = action.payload;
    },
    setLanguageId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    resetLanguages: (state) => {
      state.picture = null;
      state.languageCode = "";
      state.imageUrl = "";
      state.id = null!;
    },
  },
});

export const {
  setLanguagePicture,
  setLanguageCode,
  setLanguageImageUrl,
  setLanguageId,
  resetLanguages,
} = languageSlice.actions;
