import Stack from "@mui/material/Stack";

type FormProps = {
  children: JSX.Element[] | JSX.Element;
  direction: "column" | "row" | undefined;
  spacing: number;
};
export function Form({ children, direction, spacing }: FormProps) {
  return (
    <Stack spacing={spacing} direction={direction}>
      {children}
    </Stack>
  );
}
