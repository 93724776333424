import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomModal,
  Form,
  InfoModal,
  Input,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import {
  resetUser,
  setUserEmail,
} from "../../../constants/reducers/user.reducer";
import { createAdminAction } from "../../../actions/admin";
import { QueryKeys } from "../../../constants/query-keys";

type MutateAdminModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateAdminModal({ handleVisible }: MutateAdminModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { email } = useAppSelector((state: RootState) => state.user);

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: createAdminAction,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.adminQuery]);
      dispatch(resetUser());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleEmailChange = (value: string) => {
    dispatch(setUserEmail(value));
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleSave = () => {
    mutate();
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">{t("admin-create-modal-title")}</h3>
            <Input
              required={true}
              onChange={handleEmailChange}
              value={email}
              label={t("email")}
              type="text"
            />

            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("admin-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
