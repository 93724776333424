import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  contactSlice,
  holidaySlice,
  languageSlice,
  messageConstantSlice,
  messageSlice,
  newsContantSlice,
  newsSlice,
  openedHoursConstantSlice,
  openedHoursSlice,
  ticketSlice,
  ticketTypeSlice,
  userSlice,
} from "../constants/reducers";

export const store = configureStore({
  reducer: {
    news: newsSlice.reducer,
    languages: languageSlice.reducer,
    newsContant: newsContantSlice.reducer,
    contact: contactSlice.reducer,
    messageConstant: messageConstantSlice.reducer,
    message: messageSlice.reducer,
    holiday: holidaySlice.reducer,
    openingHoursConstant: openedHoursConstantSlice.reducer,
    openingHours: openedHoursSlice.reducer,
    ticket: ticketSlice.reducer,
    ticketType: ticketTypeSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
