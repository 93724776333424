import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomEditIcon } from "../../../components";
import { useAppDispatch } from "../../../constants/hooks/global-hooks";
import { OpeningHours } from "../../../constants/entity";
import {
  setOpenedHoursId,
  setOpenedHoursName,
} from "../../../constants/reducers/opening-hours.reducer";

type TableRowsProps = {
  data: OpeningHours;
  onModifyClick: () => void;
};

export function TableRows({ data, onModifyClick }: TableRowsProps) {
  const dispatch = useAppDispatch();

  const handleOpenModifyModal = (record: OpeningHours) => {
    dispatch(setOpenedHoursId(record.id));
    dispatch(setOpenedHoursName(record.name));
    onModifyClick();
  };
  return (
    <TableRow key={data?.id}>
      <TableCell component="th" scope="row" align="center">
        {data?.name}
      </TableCell>
      <TableCell align="center">{data?.language}</TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
    </TableRow>
  );
}
