import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import { useAppTranslation } from "../../constants/hooks";
import {
  createVersionAction,
  getAllVersionAction,
} from "../../actions/version";
import { VersionColumns, VersionsTableHeaders } from "./components";
import { useAppState } from "../../constants/hooks/global-hooks";

import "./version.page.scss";
import { QueryKeys } from "../../constants/query-keys";

export function VersionPage() {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    modalVisible: false,
  });

  const { data } = useQuery({
    queryKey: [QueryKeys.versionQuery],
    queryFn: getAllVersionAction,
  });

  const { mutate, error } = useMutation({
    mutationFn: createVersionAction,
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.versionQuery]);
    },
    onError() {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });

  const handleCreateVersion = () => {
    mutate();
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  return (
    <main className="page">
      <div className="version-page">
        <h1>{t("version-page-title")}</h1>
        <CustomButton
          type="contained"
          title={t("create-version-button-title")}
          onClick={handleCreateVersion}
        />
        <CustomTable
          data={data?.versions}
          headers={VersionsTableHeaders}
          Columns={VersionColumns}
        />
        {state.modalVisible && (
          <ErrorModal error={error} handleClose={handleClose} />
        )}
      </div>
    </main>
  );
}
