import axios from "axios";
import { store } from "../../store";

export async function createMessageConstantAction() {
  const token = document.cookie.substring(6);
  const { validFrom, validTo } = store.getState().messageConstant;
  const params = {
    validFrom,
    validTo,
  };

  return await axios
    .post(
      `${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/message-constants`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}
