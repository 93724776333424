import React from "react"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ErrorHandler } from "../../constants/errors";

type InfoModalProps = {
    severity: "success" | "error" | "warning" | "info"
    message: string
    handleClose: () => void
    error: any | null
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function InfoModal({ severity, message, handleClose, error }: InfoModalProps) {
    return (
        <Snackbar
            onClose={handleClose}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            open={true}
            key={"top" + "center"}
        >
            <Alert severity={severity}>{error ? ErrorHandler({ code: error?.response.data.code }) : message}</Alert>
        </Snackbar>
    )
}