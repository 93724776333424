import { Popover } from "@mui/material";
import { CustomButton } from "../button";
import "./popup.scss";

type PopupProps = {
  message: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
  open: boolean;
  anchorEl: any;
};

export function Popup({
  message,
  handleCancel,
  handleConfirm,
  cancelButtonTitle,
  confirmButtonTitle,
  open,
  anchorEl,
}: PopupProps) {
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="popup-message">{message}</div>
      <div className="popup-buttons">
        <CustomButton
          onClick={handleConfirm}
          title={confirmButtonTitle}
          type="contained"
        />
        <CustomButton
          onClick={handleCancel}
          title={cancelButtonTitle}
          type="contained"
        />
      </div>
    </Popover>
  );
}
