import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useAppTranslation } from "../../constants/hooks";
import "./table.scss";

type TableProps = {
  data: any;
  headers: string[];
  Columns: any;
  onModifyClick?: () => void;
  onModifyOffData?: () => void;
};

export function CustomTable({
  data,
  headers,
  Columns,
  onModifyClick,
  onModifyOffData,
}: TableProps) {
  const { t } = useAppTranslation();
  return (
    <TableContainer className="table" component={Paper}>
      <Table
        className="table-columns"
        sx={{ minWidth: 700 }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => {
              return <TableCell align="center">{t(header)}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <Columns
              data={row}
              onModifyClick={onModifyClick}
              onModifyOffData={onModifyOffData}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
