import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomDatePicker,
  CustomModal,
  Form,
  InfoModal,
  PictureUploader,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import {
  resetNewsContants,
  setNewsContantDate,
  setNewsContantImageUrl,
  setNewsContantPicture,
} from "../../../constants/reducers/news-contant.reducer";
import {
  createNewsConstantAction,
  modifyNewsConstantAction,
} from "../../../actions/news-contant";
import { useCallback } from "react";
import { QueryKeys } from "../../../constants/query-keys";

type MutateNewsContantModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateNewsContantModal({
  handleVisible,
}: MutateNewsContantModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { date, picture, imageUrl, id } = useAppSelector(
    (state: RootState) => state.newsContant
  );

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: (data: FormData) =>
      id ? modifyNewsConstantAction(data) : createNewsConstantAction(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.newsConstantQuery]);
      dispatch(resetNewsContants());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    const formData = new FormData();
    if (picture) {
      const convertedFile = picture as unknown as File;
      formData.append("picture", convertedFile);
    }
    if (id) formData.append("id", id.toString());
    if (date) formData.append("date", new Date(date)?.toDateString());
    mutate(formData);
  };

  const handleDateChange = useCallback(
    (value: Date | null) => {
      dispatch(setNewsContantDate(value));
    },
    [dispatch]
  );

  const handleSetImageUrl = useCallback(
    (value: string) => {
      dispatch(setNewsContantImageUrl(value));
    },
    [dispatch]
  );

  const handleSetPicture = useCallback(
    (value: File) => {
      dispatch(setNewsContantPicture(value));
    },
    [dispatch]
  );

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">
              {id ? t("modify") : t("news-contant-create-modal-title")}
            </h3>
            <CustomDatePicker value={date} onChange={handleDateChange} />
            <PictureUploader
              picture={picture as File}
              pictureUrl={imageUrl}
              setPictureUrl={handleSetImageUrl}
              setPicture={handleSetPicture}
            />
            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("news-constant-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
