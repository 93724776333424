import { useAppTranslation } from "../../constants/hooks";
import { ProfileForm } from "./components";

export function ProfilePage() {
  const { t } = useAppTranslation();
  return (
    <main className="page">
      <h1>{t("profile-button-title")}</h1>
      <ProfileForm />
    </main>
  );
}
