import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { getMeAction } from "../../actions/user";
import { useAppState } from "../hooks/global-hooks";
import { ErrorModal } from "../../components";
import { QueryKeys } from "../query-keys";

interface RequireAuthProps {
  children: JSX.Element;
}

export const AuthHandler = ({ children }: RequireAuthProps) => {
  const [state, setState] = useAppState({
    modalVisible: false,
  });
  const { data, error } = useQuery({
    queryKey: [QueryKeys.userQuery],
    queryFn: getMeAction,
    staleTime: 1000 * 60 * 5,
    onError: () => {
      setState((prevState) => ({ ...prevState, modalVisible: true }));
    },
  });
  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  if (data !== null && data !== undefined && !error) {
    if (window.location.pathname === "/") {
      return <Navigate to={"/cms/languages"} />;
    }
  }
  return (
    <main>
      {children}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
};
