import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomModal,
  Form,
  InfoModal,
  Input,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  resetTicketType,
  setTicketTypeCurrency,
  setTicketTypeInformationDescription,
  setTicketTypeInformationTitle,
  setTicketTypeName,
  setTicketTypePrice,
  setTicketTypeUnit,
} from "../../../constants/reducers/ticket-type.reducer";

import { RootState } from "../../../store";
import {
  createTicketTypeAction,
  modifyTicketTypeAction,
} from "../../../actions/ticket-type";
import { resetTicket } from "../../../constants/reducers/ticket.reducer";
import { QueryKeys } from "../../../constants/query-keys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

type MutateTicketTypeModalProps = {
  handleVisible: () => void;
};

export function TicketTypeMutationForm({
  handleVisible,
}: MutateTicketTypeModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const {
    id,
    name,
    price,
    currency,
    unit,
    ticketInfomationTitle,
    ticketInfomationDescription,
  } = useAppSelector((state: RootState) => state.ticketType);
  const queryClient = useQueryClient();
  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: id ? modifyTicketTypeAction : createTicketTypeAction,
    onSuccess() {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
      dispatch(resetTicketType());
      dispatch(resetTicket());
      return handleVisible();
    },
    onError() {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleNameChange = (value: string) => {
    dispatch(setTicketTypeName(value as string));
  };

  const handleCurrencyChange = (value: string) => {
    dispatch(setTicketTypeCurrency(value));
  };

  const handleInformationTitleChange = (value: string) => {
    dispatch(setTicketTypeInformationTitle(value));
  };

  const handleInformationDescriptionChange = (value: string) => {
    dispatch(setTicketTypeInformationDescription(value));
  };

  const handlePriceChange = (value: number) => {
    if (value <= 0) {
      return;
    }
    dispatch(setTicketTypePrice(value));
  };

  const handleUnitChange = (value: string) => {
    dispatch(setTicketTypeUnit(value));
  };

  const handleSave = () => {
    mutate();
  };

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <Input
              required={true}
              onChange={handleNameChange}
              value={name}
              label={t("ticket-type-name")}
              helperText={
                name.length === 0 ? t("filed-is-mandatory-max-255") : ""
              }
              error={name.length === 0}
              type="text"
            />
            <Input
              required={true}
              onChange={() => {}}
              value={price}
              label={t("ticket-type-price")}
              helperText={
                price <= 0 ? t("number-value-must-be-heigher-than-0") : ""
              }
              error={price <= 0}
              type="number"
              onNumberChange={handlePriceChange}
            />

            <Input
              required={true}
              onChange={handleCurrencyChange}
              value={currency}
              label={t("ticket-type-currency")}
              helperText={
                currency.length === 0 ? t("filed-is-mandatory-max-255") : ""
              }
              error={currency.length === 0}
              type="text"
            />

            <Input
              required={true}
              onChange={handleUnitChange}
              value={unit}
              label={t("ticketPrice-unit")}
              helperText={
                currency.length === 0 ? t("filed-is-mandatory-max-255") : ""
              }
              error={currency.length === 0}
              type="text"
            />

            <Input
              required={
                ticketInfomationDescription.length > 0 &&
                ticketInfomationTitle.length === 0
              }
              onChange={handleInformationTitleChange}
              value={ticketInfomationTitle}
              label={t("ticket-type-info-title")}
              helperText={
                ticketInfomationDescription.length > 0 &&
                ticketInfomationTitle.length === 0
                  ? t("filed-is-mandatory-max-255")
                  : ""
              }
              error={
                ticketInfomationDescription.length > 0 &&
                ticketInfomationTitle.length === 0
              }
              type="text"
            />

            <Input
              required={
                ticketInfomationTitle.length > 0 &&
                ticketInfomationDescription.length === 0
              }
              onChange={handleInformationDescriptionChange}
              value={ticketInfomationDescription}
              label={t("ticket-type-info-description")}
              helperText={
                ticketInfomationTitle.length > 0 &&
                ticketInfomationDescription.length === 0
                  ? t("filed-is-mandatory-max-255")
                  : ""
              }
              error={
                ticketInfomationTitle.length > 0 &&
                ticketInfomationDescription.length === 0
              }
              type="text"
            />

            <CustomButton onClick={handleSave} title={"save"} type="outlined" />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
