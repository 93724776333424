import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { NewsContants } from "../../../constants/entity";
import { Container } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppState } from "../../../constants/hooks/global-hooks";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { TableRows } from "./table-row";
import { NewsTableHeaders } from "./news-table-header";
import { NewsContantTableRow } from "./news-contant-table-row";

type NewsColumnsProps = {
  data: NewsContants;
  onModifyClick: () => void;
  onModifyOffData: () => void;
};

const containerStyle = {
  margin: 1,
};

export function NewsColumns({
  data,
  onModifyClick,
  onModifyOffData,
}: NewsColumnsProps) {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    infoModalVisible: false,
    open: false,
  });

  return (
    <React.Fragment>
      <NewsContantTableRow
        onModifyClick={onModifyClick}
        data={data}
        setState={setState}
        state={state}
      />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={state.open} timeout="auto" unmountOnExit>
            <Container style={containerStyle}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {NewsTableHeaders.map((header) => {
                      return <TableCell align="center">{t(header)}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.news?.map((news) => (
                    <TableRows data={news} onModifyClick={onModifyOffData} />
                  ))}
                </TableBody>
              </Table>
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
