import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  CustomButton,
  CustomModal,
  Form,
  InfoModal,
  Input,
  PictureUploader,
} from "../../../components";
import {
  useAppDispatch,
  useAppSelector,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  createLanguageAction,
  modifyLanguageAction,
} from "../../../actions/language";
import { RootState } from "../../../store";
import {
  resetLanguages,
  setLanguageCode,
  setLanguagePicture,
  setLanguageImageUrl,
} from "../../../constants/reducers/language.reducer";
import { useCallback } from "react";
import { QueryKeys } from "../../../constants/query-keys";
type MutateLanguageModalProps = {
  handleVisible: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 12,
  borderRadius: 12,
  p: 4,
};

export function MutateLanguageModal({
  handleVisible,
}: MutateLanguageModalProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { languageCode, picture, imageUrl, id } = useAppSelector(
    (state: RootState) => state.languages
  );

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const { mutate, error } = useMutation({
    mutationFn: (data: FormData) =>
      id ? modifyLanguageAction(data) : createLanguageAction(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.languageQuery]);
      dispatch(resetLanguages());
      return handleVisible();
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleSave = () => {
    const formData = new FormData();
    if (id !== null) {
      formData.append("id", id.toString());
    }
    const convertedFile = picture as unknown as File;
    formData.append("languageCode", languageCode);
    formData.append("picture", convertedFile);
    mutate(formData);
  };

  const handleLanguageCodeChange = (value: string) => {
    dispatch(setLanguageCode(value));
  };

  const handleSetImageUrl = useCallback(
    (value: string) => {
      dispatch(setLanguageImageUrl(value));
    },
    [dispatch]
  );

  const handleSetPicture = useCallback(
    (value: File) => {
      dispatch(setLanguagePicture(value));
    },
    [dispatch]
  );

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  return (
    <main>
      <CustomModal handleClose={handleVisible}>
        <Container style={style}>
          <Form direction="column" spacing={2}>
            <h3 className="title-text-h3">
              {id ? t("modify") : t("language-create-modal-title")}
            </h3>
            <Input
              required={true}
              onChange={handleLanguageCodeChange}
              value={languageCode}
              label={t("language-code-label")}
              type="text"
            />
            <PictureUploader
              picture={picture as File}
              pictureUrl={imageUrl}
              setPictureUrl={handleSetImageUrl}
              setPicture={handleSetPicture}
            />
            <CustomButton
              onClick={handleSave}
              title={t("save")}
              type="outlined"
            />
          </Form>
        </Container>
      </CustomModal>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </main>
  );
}
