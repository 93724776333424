import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Contact } from "../../../constants/entity";
import { CustomEditIcon, InfoModal } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppDispatch, useAppState } from "../../../constants/hooks/global-hooks";
import { setContactEmail, setContactId, setContactPhoneNumber } from "../../../constants/reducers/contact.reducer";

type ContactColumnsProps = {
  data: Contact;
  onModifyClick: () => void
};

export function ContactColumns({ data, onModifyClick }: ContactColumnsProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch()

  const [state, setState] = useAppState({
    infoModalVisible: false,
  });

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleOpenModifyModal = (record: Contact) => {
    dispatch(setContactId(record.id));
    dispatch(setContactPhoneNumber(record.phoneNumber));
    dispatch(setContactEmail(record.email));
    onModifyClick();
  };
  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" align="center">
        {data.email}
      </TableCell>
      <TableCell align="center">{data.phoneNumber}</TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={""}
        />
      )}
    </TableRow>
  );
}
