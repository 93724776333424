export const QueryKeys = {
  languageQuery: "languages",
  newsConstantQuery: "newsConstant",
  newsQuery: "news",
  versionQuery: "versions",
  contactQuery: "contacts",
  messageConstantQuery: "messageConstant",
  messageQuery: "message",
  holidayQuery: "holiday",
  openingHourConstantQuery: "openingHourConstant",
  openingHourQuery: "openingHour",
  ticketQuery: "tickets",
  adminQuery: "admins",
  userQuery: "user",
  forgotPasswordConfirmQuery: "forgotPassword",
};
