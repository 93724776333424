import axios from "axios";
import { store } from "../../store";

export async function modifyHolidayAction() {
  const token = document.cookie.substring(6);
  const { id, from, to, opened } = store.getState().holiday;

  const params = {
    id,
    from,
    to,
    opened,
  };
  return await axios
    .put(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/holiday`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
