import { useQuery } from "@tanstack/react-query";
import { useAppTranslation } from "../../constants/hooks";
import { useAppState } from "../../constants/hooks/global-hooks";
import { getAllAdminAction } from "../../actions/admin";
import { CustomButton, CustomTable, ErrorModal } from "../../components";
import {
  AdminColumns,
  AdminTableHeaders,
  MutateAdminModal,
} from "./components";
import { QueryKeys } from "../../constants/query-keys";

export function AdminsPage() {
  const { t } = useAppTranslation();
  const [state, setState] = useAppState({
    modalVisible: false,
    mutateModalVisible: false,
  });

  const { data, error } = useQuery({
    queryKey: [QueryKeys.adminQuery],
    queryFn: getAllAdminAction,
  });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modalVisible: false }));
  };

  const handleMutateModal = () => {
    setState((prevState) => ({
      ...prevState,
      mutateModalVisible: !state.mutateModalVisible,
    }));
  };
  return (
    <main className="page">
      <h1>{t("admins-button-title")}</h1>
      <div className="table-position">
        <CustomButton
          type="contained"
          title={t("create-admin-button-title")}
          onClick={handleMutateModal}
        />
        <CustomTable
          data={data?.admins}
          headers={AdminTableHeaders}
          Columns={AdminColumns}
        />
      </div>
      {state.mutateModalVisible && (
        <MutateAdminModal handleVisible={handleMutateModal} />
      )}
      {state.modalVisible && (
        <ErrorModal error={error} handleClose={handleClose} />
      )}
    </main>
  );
}
