import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Language } from "../../../constants/entity";
import { CustomEditIcon, DeleteIcon, InfoModal } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteLanguageAction } from "../../../actions/language";
import {
  useAppDispatch,
  useAppState,
} from "../../../constants/hooks/global-hooks";
import {
  setLanguageCode,
  setLanguageId,
  setLanguageImageUrl,
} from "../../../constants/reducers/language.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type LanguageColumnsProps = {
  data: Language;
  onModifyClick: () => void;
};

export function LanguageColumns({ data, onModifyClick }: LanguageColumnsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [state, setState] = useAppState({
    infoModalVisible: false,
  });
  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteLanguageAction({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.languageQuery]);
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const handleCloseInfoModal = () => {
    setState((prevState) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleConfirm = (id: number) => {
    mutate(id);
  };

  const handleOpenModifyModal = (record: Language) => {
    dispatch(setLanguageId(record.id));
    dispatch(setLanguageCode(record.languageCode));
    dispatch(setLanguageImageUrl(record.url));
    onModifyClick();
  };

  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row" align="center">
        <img className="image" src={data.url} alt={data.languageCode} />
      </TableCell>
      <TableCell align="center">{data.languageCode}</TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-language-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
