import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { CustomIconButton } from "../icon-button";

type ZoomOutIconProps = {
  onClick: () => void;
};

export function CustomZoomOutIcon({ onClick }: ZoomOutIconProps) {
  return (
    <CustomIconButton onClick={onClick}>
      <ZoomOutIcon />
    </CustomIconButton>
  );
}
