import { NewsContants } from "../../../constants/entity";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import {
  useAppDispatch,
  useAppNavigate,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CustomEditIcon,
  DeleteIcon,
  InfoModal,
} from "../../../components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteNewsConstantAction } from "../../../actions/news-contant";
import { getAllLanguageAction } from "../../../actions/language";

import {
  setNewsContantDate,
  setNewsContantId,
  setNewsContantImageUrl,
} from "../../../constants/reducers/news-contant.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type NewsColumnsProps = {
  data: NewsContants;
  setState: (prevState: any) => void;
  state: {
    open: boolean;
    infoModalVisible: boolean;
  };
  onModifyClick: () => void;
};

export function NewsContantTableRow({
  data,
  setState,
  state,
  onModifyClick,
}: NewsColumnsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteNewsConstantAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.newsConstantQuery]);
    },
    onError: () => {
      setState((prevState: any) => ({ ...prevState, infoModalVisible: true }));
    },
  });

  const languageQuery = useQuery({
    queryKey: [QueryKeys.languageQuery],
    queryFn: getAllLanguageAction,
  });

  const handleConfirm = (id: number) => {
    mutate(id);
  };
  const handleCloseInfoModal = () => {
    setState((prevState: any) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleOpenOffData = () => {
    setState((prevState: any) => ({ ...prevState, open: !state.open }));
  };

  const handleClickAdd = (id: number) => {
    return navigate(`create/${id}`);
  };

  const handleOpenModifyModal = (record: NewsContants) => {
    dispatch(setNewsContantId(record.id));
    dispatch(setNewsContantImageUrl(record.url));
    dispatch(setNewsContantDate(new Date(record.date)));
    onModifyClick();
  };

  return (
    <TableRow key={data?.id}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={handleOpenOffData}
        >
          {state.open ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <img className="image" src={data?.url} alt={data?.url} />
      </TableCell>
      <TableCell align="center">
        {new Date(data?.date).toLocaleDateString()}
      </TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-news-popup-message")}
          cancelButtonTitle={t("no")}
          confirmButtonTitle={t("yes")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {!languageQuery.isLoading &&
        !(languageQuery.data!.languages.length === data.news.length) && (
          <TableCell align="center">
            <AddIcon onClick={() => handleClickAdd(data.id)} />
          </TableCell>
        )}

      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("language-save-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
