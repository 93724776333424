import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Container,
  CustomTooltip,
  MenuAnchorIcon,
  Navlink,
} from "../../../components";
import {
  useAppNavigate,
  useAppState,
  useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { Pages, Settings } from "../../../constants/pages";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { invalidateToken } from "../../../constants/helper";
import { logoutAction } from "../../../actions/user";
import "./header.layout.scss";
import { QueryKeys } from "../../../constants/query-keys";

const style = {
  maxWidth: "calc(100% - 64px)",
  marginLeft: "32px",
};

const containerStyle = {
  flexGrow: 1,
  display: {
    xs: "flex",
    md: "none",
  },
};

const profileContainerStyle = {
  flexGrow: 0,
};

const pagesContainerStyle = {
  flexGrow: 1,
  display: {
    xs: "none",
    md: "flex",
  },
};

export function Header() {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();

  const [state, setState] = useAppState({
    anchorElNav: null,
    anchorElUser: null,
  });
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: logoutAction,
    onSuccess: () => {
      invalidateToken()
      queryClient.invalidateQueries([QueryKeys.userQuery]);
    },
  });


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      anchorElNav: event.currentTarget as unknown as null,
    }));
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      anchorElUser: event.currentTarget as unknown as null,
    }));
  };

  const handleCloseNavMenu = () => {
    setState((prevState) => ({
      ...prevState,
      anchorElNav: null,
    }));
  };

  const handleCloseUserMenu = (path: string) => {
    if (path === "/") {
      mutate();
    }

    setState((prevState) => ({
      ...prevState,
      anchorElUser: null,
    }));
    return navigate(path);
  };

  return (
    <main className="header">
      <AppBar position="static">
        <Container style={style}>
          <Toolbar disableGutters>
            <Container style={containerStyle}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuAnchorIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={state.anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(state.anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >

                {Pages().map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <div onClick={() => navigate(`${page.path}`)}>
                        {page.name}
                      </div>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Container>
            <Container style={pagesContainerStyle}>
              {Pages().map((page) => (
                <div
                  className={`pages-buttons ${window.location.pathname.includes(page.path) ? "active" : ""
                    }`}
                >
                  <Navlink to={page.path} title={page.name} />
                </div>
              ))}
            </Container>
            <Container style={profileContainerStyle}>
              <CustomTooltip title={t("settings-button-tooltip-title")}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="profile" />
                </IconButton>
              </CustomTooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={state.anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(state.anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {Settings().map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => handleCloseUserMenu(setting.path)}
                  >
                    <Typography textAlign="center">
                      <Navlink to={setting.path} title={setting.name} />
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Container>
          </Toolbar>
        </Container>
      </AppBar>
    </main>
  );
}
