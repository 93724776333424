import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useAppTranslation } from "../../constants/hooks";
import { useAppDispatch, useAppState } from "../../constants/hooks/global-hooks";
import { useQuery } from "@tanstack/react-query";
import { getAllLanguageAction } from "../../actions/language";
import { Language } from "../../constants/entity";
import { setLanguageId } from "../../constants/reducers/language.reducer";

import { useEffect } from "react"
type StepperProps = {
  onNextClick: () => void;
  onFinish: () => void;
  onPrevClick: (languageId: number) => void;
  stepSkips: number
};

export function HorizontalLinearStepper({
  onNextClick,
  onFinish,
  onPrevClick,
  stepSkips
}: StepperProps) {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch()

  const [state, setState] = useAppState({
    activeStep: stepSkips
  });

  const { data, isLoading } = useQuery({
    queryKey: ["languages"],
    queryFn: getAllLanguageAction,
  });

  const handleNext = (id: number) => {
    onNextClick()
    dispatch(setLanguageId(id))
    setState((prevState) => ({ ...prevState, activeStep: state.activeStep + 1 }))
  };

  const handleBack = (id: number) => {
    dispatch(setLanguageId(id))
    onPrevClick(id)
    setState((prevState) => ({ ...prevState, activeStep: state.activeStep - 1 }))
  };

  useEffect(() => {
    if (stepSkips) {
      dispatch(setLanguageId(data.languages[stepSkips].id))
    }
  }, [dispatch, data, stepSkips])


  return (
    <Box sx={{ width: "100%" }}>

      <Stepper activeStep={state.activeStep}>
        {!isLoading && (
          data.languages.map((label: Language,) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label.id} {...stepProps}>
                <StepLabel {...labelProps}>{label.languageCode}</StepLabel>
              </Step>
            );
          })
        )}

      </Stepper>
      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {!isLoading && (
            <>
              <Button
                color="inherit"
                disabled={state.activeStep === 0}
                onClick={() => handleBack(data.languages[state.activeStep - 1].id)}
                sx={{ mr: 1 }}
              >
                {t("back")}
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                onClick={
                  state.activeStep === data.languages?.length - 1
                    ? () => onFinish()
                    : () => handleNext(data.languages[state.activeStep + 1].id)
                }
              >
                {state.activeStep === data.languages?.length - 1 ? t("finish") : t("next")}
              </Button>
            </>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
}
