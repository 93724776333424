import Box from "@mui/material/Box";

type ContainerProps = {
  children: JSX.Element | JSX.Element[];
  style: object;
};

export function Container({ children, style }: ContainerProps) {
  return (
    <Box component="form" sx={style}>
      {children}
    </Box>
  );
}
