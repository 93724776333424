import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OpeningHoursState } from "../state";

const initialState: OpeningHoursState = {
  name: "",
  id: null,
};

export const openedHoursSlice = createSlice({
  name: "openedHours",
  initialState,
  reducers: {
    setOpenedHoursName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setOpenedHoursId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    resetOpenedHours: (state) => {
      state.name = "";
      state.id = null;
    },
  },
});

export const { setOpenedHoursName, setOpenedHoursId, resetOpenedHours } =
  openedHoursSlice.actions;
