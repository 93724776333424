import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MessageConstant } from "../../../constants/entity";
import {
    Container,
} from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppState } from "../../../constants/hooks/global-hooks";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { TableRows } from "./table-row";
import { MessageContantTableRow } from "./message-constant-table-row";
import { MessageTableHeaders } from "./message-table-header";

type MessageColumnsProps = {
    data: MessageConstant
    onModifyClick: () => void;
    onModifyOffData: () => void;
};

const containerStyle = {
    margin: 1,
};

export function MessageColumns({ data, onModifyClick, onModifyOffData }: MessageColumnsProps) {
    const { t } = useAppTranslation();
    const [state, setState] = useAppState({
        infoModalVisible: false,
        open: false,
    });

    return (
        <React.Fragment>
            <MessageContantTableRow onModifyClick={onModifyClick} data={data} setState={setState} state={state} />
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={state.open} timeout="auto" unmountOnExit>
                        <Container style={containerStyle}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {MessageTableHeaders.map((header) => {
                                            return <TableCell align="center">{t(header)}</TableCell>;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.messages?.map((message) => (
                                        <TableRows data={message} onModifyClick={onModifyOffData} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Container>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
