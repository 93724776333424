import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HolidayState } from "../state";

const initialState: HolidayState = {
  id: null!,
  from: null,
  to: null,
  opened: false,
};

export const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    setHolidayId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setHolidayFrom: (state, action: PayloadAction<Date | null>) => {
      state.from = action.payload;
    },
    setHolidayTo: (state, action: PayloadAction<Date | null>) => {
      state.to = action.payload;
    },
    setHolidayOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    resetHoliday: (state) => {
      state.from = null;
      state.to = null;
      state.opened = false;
    },
  },
});

export const {
  setHolidayId,
  setHolidayFrom,
  setHolidayTo,
  setHolidayOpened,
  resetHoliday,
} = holidaySlice.actions;
