import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { AppDispatch, RootState } from "../../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppNavigate = () => useNavigate();
export const useAppParams = () => useParams();
export const useAppTranslation = () => useTranslation();
export const useAppState = <T>(
  initialState: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  return useState<T>(initialState);
};
export const useAppMemo = <T>(
  factory: () => T,
  deps: React.DependencyList
): T => {
  return useMemo(factory, [factory, deps]);
};
