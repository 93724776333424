import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomEditIcon, DeleteIcon, InfoModal } from "../../../components";
import { useAppTranslation } from "../../../constants/hooks";
import { useAppDispatch } from "../../../constants/hooks/global-hooks";
import { TicketType } from "../../../constants/entity";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTicketTypeAction } from "../../../actions/ticket-type";
import {
  setTicketTypeCurrency,
  setTicketTypeId,
  setTicketTypeInformationDescription,
  setTicketTypeInformationTitle,
  setTicketTypeName,
  setTicketTypePrice,
  setTicketTypeUnit,
} from "../../../constants/reducers/ticket-type.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type TableRowsProps = {
  data: TicketType;
  setState: (prevState: any) => void;
  state: {
    open: boolean;
    infoModalVisible: boolean;
    mutateModalVisible: boolean;
  };
};

export function TicketTypeTableRows({ data, state, setState }: TableRowsProps) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate, error } = useMutation({
    mutationFn: (id: number) => deleteTicketTypeAction(id),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ticketQuery]);
    },
    onError: () => {
      setState((prevState: any) => ({ ...prevState, infoModalVisible: true }));
    },
  });
  const handleCloseInfoModal = () => {
    setState((prevState: any) => ({ ...prevState, infoModalVisible: false }));
  };

  const handleConfirm = (id: number) => {
    mutate(id);
  };

  const handleOpenModifyModal = (record: TicketType) => {
    dispatch(setTicketTypeId(record.id));
    dispatch(setTicketTypeName(record.name));
    dispatch(setTicketTypeCurrency(record.ticketPrice.currency));
    dispatch(setTicketTypePrice(record.ticketPrice.price));
    dispatch(setTicketTypeUnit(record.ticketPrice.unit));
    if (record.ticketInformation) {
      dispatch(
        setTicketTypeInformationDescription(
          record.ticketInformation.description
        )
      );
      dispatch(setTicketTypeInformationTitle(record.ticketInformation.title));
    }
    setState((prevState: any) => ({ ...prevState, mutateModalVisible: true }));
  };

  return (
    <TableRow key={data?.id}>
      <TableCell component="th" scope="row" align="center">
        {data?.name.length > 100 ? data.name.substring(0, 100) : data.name}
      </TableCell>
      <TableCell align="center">{data?.ticketPrice.price}</TableCell>
      <TableCell align="center">{data?.ticketPrice.currency}</TableCell>
      <TableCell align="center">{data?.ticketPrice.unit}</TableCell>
      <TableCell align="center">
        {data?.ticketInformation?.title
          ? data?.ticketInformation.title.length > 100
            ? data?.ticketInformation.title.substring(0, 100) + "..."
            : data?.ticketInformation.title
          : " "}
      </TableCell>
      <TableCell align="center">
        {data?.ticketInformation?.description
          ? data?.ticketInformation.description.length > 100
            ? data?.ticketInformation.description.substring(0, 100) + "..."
            : data?.ticketInformation.description
          : " "}
      </TableCell>
      <TableCell align="center">
        <CustomEditIcon onClick={() => handleOpenModifyModal(data)} />
      </TableCell>
      <TableCell align="center">
        <DeleteIcon
          message={t("delete-ticket-type-popup-message")}
          cancelButtonTitle={t("cancel")}
          confirmButtonTitle={t("delete")}
          handleConfirm={() => handleConfirm(data.id)}
        />
      </TableCell>
      {state.infoModalVisible && (
        <InfoModal
          severity="error"
          message={t("ticket-type-delete-error-message")}
          handleClose={handleCloseInfoModal}
          error={error}
        />
      )}
    </TableRow>
  );
}
