import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContactState } from "../state";

const initialState: ContactState = {
  email: "",
  phoneNumber: "",
  id: null!,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    setContactEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setContactPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },

    resetContact: (state) => {
      state.email = "";
      state.phoneNumber = "";
    },
  },
});

export const {
  setContactId,
  setContactEmail,
  setContactPhoneNumber,
  resetContact,
} = contactSlice.actions;
