export const TicketTypeTableHeaders = [
  "ticket-type-name",
  "ticketPrice-price",
  "ticketPrice-currency",
  "ticketPrice-unit",
  "ticketinformation-title",
  "ticketinformation-description",
  "modify",
  "delete",
];
