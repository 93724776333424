import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    Container,
    CustomButton,
    CustomDateTimePicker,
    CustomModal,
    CustomSwitch,
    Form,
    InfoModal,
} from "../../../components";
import {
    useAppDispatch,
    useAppSelector,
    useAppState,
    useAppTranslation,
} from "../../../constants/hooks/global-hooks";
import { RootState } from "../../../store";
import { createHolidayAction, modifyHolidayAction } from "../../../actions/holiday";
import { resetHoliday, setHolidayFrom, setHolidayOpened, setHolidayTo } from "../../../constants/reducers/holiday.reducer";
import { QueryKeys } from "../../../constants/query-keys";

type MutateHolidayModalProps = {
    handleVisible: () => void;
};

const style = {
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid transparent",
    boxShadow: 12,
    borderRadius: 12,
    p: 4,
};

export function MutateHolidayModal({
    handleVisible,
}: MutateHolidayModalProps) {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch()
    const queryClient = useQueryClient();
    const { id, from, to, opened } = useAppSelector((state: RootState) => state.holiday)

    const [state, setState] = useAppState({
        infoModalVisible: false,
    });

    const { mutate, error } = useMutation({
        mutationFn: id ? modifyHolidayAction : createHolidayAction,
        onSuccess: (data) => {
            queryClient.invalidateQueries([QueryKeys.holidayQuery]);
            dispatch(resetHoliday())
            return handleVisible();
        },
        onError: () => {
            setState((prevState) => ({ ...prevState, infoModalVisible: true }));
        },
    });

    const handleSave = () => {
        mutate();
    };

    const handleHolidayFromChange = (value: Date | null) => {
        dispatch(setHolidayFrom(value))
    };

    const handleHolidayToChange = (value: Date | null) => {
        dispatch(setHolidayTo(value))
    };


    const handleHolidayOpenedChange = (value: string) => {
        console.log(value)
        dispatch(setHolidayOpened(value !== "true"))
    };

    const handleCloseInfoModal = () => {
        setState((prevState) => ({ ...prevState, infoModalVisible: false }));
    };

    return (
        <main>
            <CustomModal handleClose={handleVisible}>
                <Container style={style}>
                    <Form direction="column" spacing={2}>
                        <h3 className="title-text-h3">
                            {id ? t("modify") : t("holiday-create-modal-title")}
                        </h3>
                        <CustomDateTimePicker value={from} onChange={handleHolidayFromChange} />
                        <CustomDateTimePicker value={to} onChange={handleHolidayToChange} />
                        <CustomSwitch value={opened} onChange={handleHolidayOpenedChange} title="open" />
                        <CustomButton
                            onClick={handleSave}
                            title={t("save")}
                            type="outlined"
                        />
                    </Form>
                </Container>
            </CustomModal>
            {state.infoModalVisible && (
                <InfoModal
                    severity="error"
                    message={t("holiday-save-error-message")}
                    handleClose={handleCloseInfoModal}
                    error={error}
                />
            )}
        </main>
    );
}
