import axios from "axios";

type getNewsProps = {
  languageId: number;
  constantId: number;
};

export async function getNewsAction({ languageId, constantId }: getNewsProps) {
  const token = document.cookie.substring(6);

  return await axios
    .get(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/cms/news/one`, {
      params: { languageId, constantId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}
